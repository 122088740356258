import { RootModelTypeNameDecorator, ActionApiClient, GenericServiceRequest } from '@nts/std';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsageReportFiltersDto } from '../dto/usage-report-filters.dto';

@Injectable()
@RootModelTypeNameDecorator('LicenseReport')
export class LicenseReportApiClient extends ActionApiClient {

    getAzureUsageReport(dto: UsageReportFiltersDto): Observable<any> {
        const request: GenericServiceRequest<UsageReportFiltersDto> = new GenericServiceRequest<UsageReportFiltersDto>();
        request.requestData = dto;

        return this.getWebApiServiceAgent()
            .invokePostDownloadFile('GetAzureUsageReport', request);
    }
}

import { SoftwareService } from 'src/app/software-service/domain-models/software-service';
import { SoftwareServiceIdentity } from 'src/app/software-service/domain-models/software-service.identity';
import {
    IdentityTypeDecorator, ExternalDomainModelTypeDecorator,
    ExternalDomainModelTypeNameDecorator, ExternalViewModel,
    NumericPropertyViewModel, AutoCompleteExternalOptions, OrderBy,
    OrderByType, StringPropertyViewModel
} from '@nts/std';

@IdentityTypeDecorator(SoftwareServiceIdentity)
@ExternalDomainModelTypeDecorator(SoftwareService)
@ExternalDomainModelTypeNameDecorator('SoftwareService')
export class SoftwareServiceExtViewModel extends ExternalViewModel<SoftwareService, SoftwareServiceIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: StringPropertyViewModel;

    public get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _title: StringPropertyViewModel;
    public get title(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._title = value; }, this._title, 'title');
    }


    public override async postInit(): Promise<void> {
        await super.postInit();

        this.autocomplete = true;
        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.propertySearchList = ['Code', 'Title'];
        this.autoCompleteOptions.orderByPropertyNames = [
            new OrderBy({ propertyName: 'Title', sortType: OrderByType.Ascending })
        ];
        this.autoCompleteOptions.outputProperties = ['Code', 'Title'];
    }

}

import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { UploadResponseDto } from '../dto/upload-response.dto';


export const STEPS = {
    selectModule: 'select-module',
    operationComplete: 'operation-complete',
};

interface FormDataInterface {
    'select-module'?: UploadResponseDto,
    'operation-complete'?: UploadResponseDto
}

@Injectable()
export class FormDataService {

    private data: FormDataInterface = {
        [STEPS.selectModule]: null,
        [STEPS.operationComplete]: null
    }

    setData<T>(step: string, data: T) {
        this.data[step] = data;
    }

    getData<T>(step: string): T {
        return cloneDeep(this.data[step]);
    }
}

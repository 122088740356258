import { IdentityTypeInspector } from "../../decorators/identity-type.decorator";
import { Expose, Type } from "../../serialization/class-transformer/decorators";
import { ModelTypeInspector } from "../decorators/model-type.decorator";
import { ReportFileSelectionDto } from "./report-file-selection.dto";

export class PrintOutDemandDto<TParams> {

    private _printOutDataType: any;

    @Expose()
    @Type((options) => {
        return (options?.newObject as PrintOutDemandDto<TParams>)?._printOutDataType;
    })
    printOutData: TParams;

    @Expose()
    @Type(() => ReportFileSelectionDto)
    reportFileSelection: ReportFileSelectionDto;

    constructor(printOutDataType?: any, param?: TParams) {

        this.reportFileSelection = new ReportFileSelectionDto();

        if (printOutDataType === undefined) {

            const modelType = ModelTypeInspector.getValue(this);
            const identityType = IdentityTypeInspector.getValue(this);

            this._printOutDataType = modelType ?? identityType;
            if (this._printOutDataType === undefined) {
                throw new Error(
                    `MetaData ${ModelTypeInspector.META_DATA_KEY} or ${IdentityTypeInspector.META_DATA_KEY} are not defined. You must use ${ModelTypeInspector.DECORATOR_NAME} or ${IdentityTypeInspector.DECORATOR_NAME}in ${this.constructor.name}.`
                );
            }

        } else {
            this._printOutDataType = printOutDataType;
        }

        this.printOutData = param;
    }

}

import { BaseMultiLanguageViewModel } from '@nts/std';
import { StringPropertyViewModel, ExternalViewModelTypeDecorator } from '@nts/std';
import { LanguageExtViewModel } from 'src/app/external-remote/language/language.ext-view-model';
import { ModuleML } from '../domain-models/module-ml';
import { ModuleMLIdentity } from '../domain-models/module-ml.identity';

export class ModuleMLViewModel extends BaseMultiLanguageViewModel<ModuleML, ModuleMLIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: StringPropertyViewModel;

    public get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _title: StringPropertyViewModel;

    public get title(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._title = value; }, this._title, 'title');
    }

    // tslint:disable-next-line: member-ordering
    @ExternalViewModelTypeDecorator(LanguageExtViewModel)
    public languageRef: LanguageExtViewModel;

}

import { Observable, from, switchMap } from 'rxjs';
import { CoreModel, CompanySingleAggregateIdentity } from '../domain-models';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { GetByIdentityCachedResponse } from '../responses/get-by-identity-cached-response';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { CrudApiClient } from './crud/crud-api-client';
import { SingleAggregateApiClientInterface } from './single-aggregate-api-client.interface';
import { CacheOptionsInterface, WebApiServiceAgent } from './web-api-service-agent';
import { EnterpriseDataDto } from '../auth/dto/enterprise-data.dto';
import { Injectable } from '@angular/core';
import { OnlineService } from '../utility/offline.service';
import { CurrentSessionService } from '../auth';

@Injectable()
export class CompanySingleAggregateApiClient<TModel extends CoreModel<TIdentity>, TIdentity extends CompanySingleAggregateIdentity>
    extends CrudApiClient<TModel, TIdentity> implements SingleAggregateApiClientInterface {

        constructor(
            agent: WebApiServiceAgent,
            onlineService: OnlineService,
            protected currentSession: CurrentSessionService
        ) {
            super(
                agent,
                onlineService
            )
        }

        getSingleAggregateCached(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityCachedResponse<TModel, TIdentity>> {

            // Default for getSingleAggregateCached
            cacheOptions = {
                bypass: false,                         
                expirationTime: undefined,                  
                force: false,
                enterpriseBarrier: true,
                tenantBarrier: true,
                userBarrier: true,
                ...cacheOptions
            };

            const identity = new CompanySingleAggregateIdentity() as TIdentity;
            return from(this.currentSession.getEnterpriseData()).pipe(
                switchMap((enterpriseData: EnterpriseDataDto) => {
                    identity.companyId = enterpriseData?.companyId;
                    const request = new GetByIdentityRequest<TIdentity>(identity);
                    return this.getByIdentityCached(
                        request,
                        cacheOptions
                    );
                })
            )
        }

        getSingleAggregate(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
            const identity = new CompanySingleAggregateIdentity() as TIdentity;
            return from(this.currentSession.getEnterpriseData()).pipe(
                switchMap((enterpriseData: EnterpriseDataDto) => {
                    identity.companyId = enterpriseData?.companyId;
                    const request = new GetByIdentityRequest<TIdentity>(identity);
                    return this.getByIdentity(
                        request,
                        cacheOptions
                    );
                })
            )
        }
}

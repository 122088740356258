import { NTSReflection } from '@nts/std/src/lib/utility';
import cloneDeep from 'lodash-es/cloneDeep';

const IDENTITY_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY = 'identityPropertyListDecoratorMetaDataKey';

export function IdentityPropertyListDecorator(identityPropertyList: string[]) {
    return (target: any) => {
        Reflect.defineMetadata(IDENTITY_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY, identityPropertyList, target);
    };
}

export class IdentityPropertyListInspector {

    static META_DATA_KEY = IDENTITY_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@IdentityPropertyListDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            IDENTITY_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): string[] {
        return cloneDeep(NTSReflection.getClassMetadata(
            IDENTITY_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY, subject));
    }
}

import { CurrentSessionService, StdModule, ModalService, NtsAppModule, WebApiServiceAgent, ComponentLocator, OnlineService } from '@nts/std';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LicenseReportApiClient } from './api-clients/license-report.api-client';
import { LicenseReportContainerComponent } from './components/license-report-container/license-report-container.component';
import { LicenseReportComponent } from './components/license-report/license-report.component'

export const LICENSE_REPORT_FULL_PATH = 'manage/licensereport';

export const STEPS = {
  download: 'download',
};

const routes: Routes = [
  {
    path: '',
    component: LicenseReportContainerComponent,
    children: [
      { path: STEPS.download, component: LicenseReportComponent },
      { path: '', redirectTo: STEPS.download, pathMatch: 'full' },
      { path: '**', redirectTo: STEPS.download },
    ]
  },
];

@NgModule({
  imports: [
    StdModule,
    CommonModule,
    RouterModule.forChild(
      routes
    ),
  ],
  declarations: [],
  providers: [
    {
      provide: LicenseReportApiClient,
      deps: [WebApiServiceAgent, OnlineService]
    },
  ]
})
export class LicenseReportModule extends NtsAppModule {

  constructor(
    componentLocator: ComponentLocator) {

    super(
      componentLocator
    );
  }

  registerAll(
    modalService: ModalService
  ) {

  }
}

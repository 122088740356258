import { Component } from '@angular/core';
import { BaseAggregateComponent, DecodeTextBoxComponent, ExpandableComponent, GridComponent, LabelledTextBoxComponent, NTSTranslatePipe } from '@nts/std';
import { ModuleViewModel } from '../view-models/module.view-model';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-module',
    templateUrl: './module.component.html',
    standalone: true,
    imports: [
        NgIf,
        ExpandableComponent,
        LabelledTextBoxComponent,
        DecodeTextBoxComponent,
        GridComponent,
        NTSTranslatePipe
    ]
})
export class ModuleComponent extends BaseAggregateComponent<ModuleViewModel> {

}

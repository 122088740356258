import { LongOpParametersTypeInspector } from './../../decorators/long-op-parameters-type.decorator';
import { LongOpResultTypeInspector } from '../../decorators/long-op-result-type.decorator';
import { CoreModel } from '../../domain-models/core-model';
import { BaseIdentity } from '../../domain-models';
import { RootViewModel } from '../root-view-model';
import { ClassType } from '../../serialization/class-transformer/ClassTransformer';
import { InternalViewModel } from '../internal-view-model';
import { InternalViewModelTypeDecorator } from '../decorators/internal-view-model-type.decorator';
import { ClassInformationInterface } from '@nts/std/src/lib/utility';
import { LongOpRootViewModelInterface } from './long-op-root-view-model.interface';

export class LongOpRootViewModel<
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity,
    TParams extends InternalViewModel<TParamsModel, TIdentity>,
    TResult extends InternalViewModel<TResultModel, TIdentity>,
    TParamsModel extends CoreModel<TIdentity>,
    TResultModel extends CoreModel<TIdentity>>
    extends RootViewModel<TModel, TIdentity> implements LongOpRootViewModelInterface, ClassInformationInterface {

    private _paramsType: ClassType<TParams>;

    private _resultType: ClassType<TResult>;

    @InternalViewModelTypeDecorator((target: any, propertyKey: string) => {
        return target.paramsType;
    })
    params: TParams;

    @InternalViewModelTypeDecorator((target: any, propertyKey: string) => {
        return target.resultType;
    })
    result: TResult;

    get paramsType(): ClassType<TParams> {
        return this._paramsType;
    }

    get resultType(): ClassType<TResult> {
        return this._resultType;
    }

    constructor() {

        super();

        this._paramsType = LongOpParametersTypeInspector.getValue(this);
        if (this._paramsType === undefined) {
            throw new Error(
                `MetaData ${LongOpParametersTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpParametersTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._resultType = LongOpResultTypeInspector.getValue(this);
        if (this._resultType === undefined) {
            throw new Error(
                `MetaData ${LongOpResultTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpResultTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

    }
}

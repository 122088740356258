import { IdentityTypeDecorator, ModelTypeNameDecorator, OCCModel, Expose, StringDecorator, Type, InternalDecorator } from '@nts/std';
import { LanguageIdentity } from './language.identity';

@IdentityTypeDecorator(LanguageIdentity)
@ModelTypeNameDecorator('Language')
export class Language extends OCCModel<LanguageIdentity> {

    // tslint:disable-next-line: variable-name
    private _languageCode: string;

    /**
     * Codice lingua (in formato en-GB, it-IT)
     */
    @Expose()
    public get languageCode(): string {
        return this.getValueForIdentityValue<string>(() => this._languageCode, 'languageCode');
    }
    public set languageCode(value: string) {
        this.setValueForIdentityValue<string>(() => { this._languageCode = value; }, value, 'languageCode');
    }

    // tslint:disable-next-line: variable-name
    private _displayName: string;

    /**
     * Nome visualizzato
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'LanguageModel_DisplayName', maxLength: 255, isRequired: true, isMainDescription: true })
    public get displayName(): string {
        return this.getValue<string>(() => this._displayName, 'displayName');
    }
    public set displayName(value: string) {
        this.setValue<string>(() => { this._displayName = value; }, value, 'displayName');
    }

    // tslint:disable-next-line: variable-name
    private _englishName: string;

    /**
     * Nome in Inglese
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'LanguageModel_EnglishName', maxLength: 255, isRequired: true, isMainDescription: true })
    public get englishName(): string {
        return this.getValue<string>(() => this._englishName, 'englishName');
    }
    public set englishName(value: string) {
        this.setValue<string>(() => { this._englishName = value; }, value, 'englishName');
    }

    // tslint:disable-next-line: variable-name
    private _nativeName: string;

    /**
     * Nome Nativo
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'LanguageModel_NativeName', maxLength: 255, isRequired: false, isMainDescription: true })
    public get nativeName(): string {
        return this.getValue<string>(() => this._nativeName, 'nativeName');
    }
    public set nativeName(value: string) {
        this.setValue<string>(() => { this._nativeName = value; }, value, 'nativeName');
    }

    // tslint:disable-next-line: variable-name
    private _iso3LanguageName: string;

    /**
     * Nome ThreeLetterISOLanguageName
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'LanguageModel_Iso3LanguageName', maxLength: 10, isRequired: true, isMainDescription: true })
    public get iso3LanguageName(): string {
        return this.getValue<string>(() => this._iso3LanguageName, 'iso3LanguageName');
    }
    public set iso3LanguageName(value: string) {
        this.setValue<string>(() => { this._iso3LanguageName = value; }, value, 'iso3LanguageName');
    }
}



import { CoreModel, Expose, IdentityTypeDecorator, ModelTypeNameDecorator } from '@nts/std';
import { TenantIdentity } from './tenant.identity';


@IdentityTypeDecorator(TenantIdentity)
@ModelTypeNameDecorator('CompanyInformation')
export class CompanyInformation extends CoreModel<TenantIdentity> {

  // tslint:disable-next-line: variable-name
  private _id: number;

  @Expose()
  public get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  public set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  // tslint:disable-next-line: variable-name
  private _atecoCode: string;

  @Expose()
  public get atecoCode(): string {
    return this.getValue<string>(() => this._atecoCode, 'atecoCode');
  }
  public set atecoCode(value: string) {
    this.setValue<string>(() => { this._atecoCode = value; }, value, 'atecoCode');
  }

  // tslint:disable-next-line: variable-name
  private _atecoDescription: string;

  @Expose()
  public get atecoDescription(): string {
    return this.getValue<string>(() => this._atecoDescription, 'atecoDescription');
  }
  public set atecoDescription(value: string) {
    this.setValue<string>(() => { this._atecoDescription = value; }, value, 'atecoDescription');
  }

  // tslint:disable-next-line: variable-name
  private _legalRapresentativeName: string;

  @Expose()
  public get legalRapresentativeName(): string {
    return this.getValue<string>(() => this._legalRapresentativeName, 'legalRapresentativeName');
  }
  public set legalRapresentativeName(value: string) {
    this.setValue<string>(() => { this._legalRapresentativeName = value; }, value, 'legalRapresentativeName');
  }

  // tslint:disable-next-line: variable-name
  private _legalRapresentativeLastName: string;

  @Expose()
  public get legalRapresentativeLastName(): string {
    return this.getValue<string>(() => this._legalRapresentativeLastName, 'legalRapresentativeLastName');
  }
  public set legalRapresentativeLastName(value: string) {
    this.setValue<string>(() => { this._legalRapresentativeLastName = value; }, value, 'legalRapresentativeLastName');
  }

  // tslint:disable-next-line: variable-name
  private _legalRapresentativeTAXNumber: string;

  @Expose()
  public get legalRapresentativeTAXNumber(): string {
    return this.getValue<string>(() => this._legalRapresentativeTAXNumber, 'legalRapresentativeTAXNumber');
  }
  public set legalRapresentativeTAXNumber(value: string) {
    this.setValue<string>(() => { this._legalRapresentativeTAXNumber = value; }, value, 'legalRapresentativeTAXNumber');
  }

  // tslint:disable-next-line: variable-name
  private _legalRapresentativePhone: string;

  @Expose()
  public get legalRapresentativePhone(): string {
    return this.getValue<string>(() => this._legalRapresentativePhone, 'legalRapresentativePhone');
  }
  public set legalRapresentativePhone(value: string) {
    this.setValue<string>(() => { this._legalRapresentativePhone = value; }, value, 'legalRapresentativePhone');
  }

  // tslint:disable-next-line: variable-name
  private _legalRapresentativeEmail: string;

  @Expose()
  public get legalRapresentativeEmail(): string {
    return this.getValue<string>(() => this._legalRapresentativeEmail, 'legalRapresentativeEmail');
  }
  public set legalRapresentativeEmail(value: string) {
    this.setValue<string>(() => { this._legalRapresentativeEmail = value; }, value, 'legalRapresentativeEmail');
  }
}

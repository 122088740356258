import { IdentityTypeNameDecorator, Expose, MultiLanguageIdentityInterface } from '@nts/std';
import { ModuleIdentity } from './module.identity';


@IdentityTypeNameDecorator('ModuleMLIdentity')
export class ModuleMLIdentity extends ModuleIdentity implements MultiLanguageIdentityInterface {

    // tslint:disable-next-line: variable-name
    private _languageCode: string;

    @Expose()
    public get languageCode(): string {
        return this.getValue<string>(() => this._languageCode, 'languageCode');
    }
    public set languageCode(value: string) {
        this.setValue<string>(() => { this._languageCode = value; }, value, 'languageCode');
    }
}


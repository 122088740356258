import { NgModule } from '@angular/core';
import { NtsAppModule, ComponentLocator, BaseRoutes } from '@nts/std';
import { RouterModule } from '@angular/router';
import { ModuleComponent as ModuleComponent } from './component/module.component';
import { ModuleApiClient } from './api-clients/module.api-client';
import { ModuleOrchestratorViewModel } from './view-models/module.orchestrator-view-model';
import { ModuleViewModel } from './view-models/module.view-model';

export const MODULE_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.ModuleObjects.Models.Module';

@NgModule({
  imports: [
    RouterModule.forChild(
      BaseRoutes.getBaseRoutes()
    )
  ],
  declarations: [],
  providers: []
})
export class ModuleModule extends NtsAppModule {

  constructor(
    componentLocator: ComponentLocator) {

    super(
      componentLocator
    );
  }

  registerAll() {
    this.registerAllForDomainModelType(
      'Module',
      ModuleOrchestratorViewModel,
      ModuleViewModel,
      ModuleComponent,
      ModuleApiClient
    );
  }
}

import { BaseRootModel } from './base-root-model';
import { Expose, Transform } from '../serialization/class-transformer/decorators';
import { AuditModelInterface } from './audit-model.interface';
import { BaseIdentity } from './base-identity';
import { DateTimeOffset } from './date-time-offset';


export class BaseAuditModel<TIdentity extends BaseIdentity>
    extends BaseRootModel<TIdentity> implements AuditModelInterface {

    private _updateUser: string;

    @Expose()
    get updateUser(): string {
        return this.getValue<string>(() => this._updateUser, 'updateUser');
    }
    set updateUser(value: string) {
        this.setValue<string>(() => { this._updateUser = value; }, value, 'updateUser');
    }

    private _updateDate: Date;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('updateDate', value, obj, type);
    })
    get updateDate(): Date {
        return this.getValue<Date>(() => this._updateDate, 'updateDate');
    }
    set updateDate(value: Date) {
        this.setValue<Date>(() => { this._updateDate = value; }, value, 'updateDate');
    }

    private _creationUser: string;

    @Expose()
    get creationUser(): string {
        return this.getValue<string>(() => this._creationUser, 'creationUser');
    }
    set creationUser(value: string) {
        this.setValue<string>(() => { this._creationUser = value; }, value, 'creationUser');
    }

    private _creationDate: Date;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('creationDate', value, obj, type);
    })
    get creationDate(): Date {
        return this.getValue<Date>(() => this._creationDate, 'creationDate');
    }
    set creationDate(value: Date) {
        this.setValue<Date>(() => { this._creationDate = value; }, value, 'creationDate');
    }
}

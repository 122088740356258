import { NTSReflection } from '@nts/std/src/lib/utility';
import cloneDeep from 'lodash-es/cloneDeep';

const MODEL_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY = 'modelPropertyListDecoratorMetaDataKey';

export function ModelPropertyListDecorator(modelPropertyList: string[]) {
    return (target: any) => {
        Reflect.defineMetadata(MODEL_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY, modelPropertyList, target);
    };
}

export class ModelPropertyListInspector {

    static META_DATA_KEY = MODEL_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ModelPropertyListDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            MODEL_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): string[] {
        return cloneDeep(NTSReflection.getClassMetadata(
            MODEL_PROPERTY_LIST_META_DATA_IDENTIFICATION_KEY, subject));
    }
}

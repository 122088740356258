import { Injectable } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';
import 'reflect-metadata';
import { LocalstorageHelper } from './localstorage-helper';

@Injectable()
export class DeviceHelper {

    constructor() {
    }

    // legge il device id se c'è da local storage altrimenti lo genera
    async deviceID() {

        let strDeviceID = await this.getDeviceID();
        if (strDeviceID === '') {
            strDeviceID = await this.generateDeviceID();
            await this.saveDeviceID(strDeviceID);
        }

        return strDeviceID;
    }

    // genera il device id con la libreria Fingerprint2
    async generateDeviceID() {
        // https://www.npmjs.com/package/fingerprintjs2
        const fingerPrint = await (new Promise<string>(
            (resolve, reject) => {
                const options = {};
                Fingerprint2.get(options, (components: any) => {
                    const values = components.map((component: any) => component.value);
                    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
                    resolve(murmur);
                });
            }
        ));
        return fingerPrint;
    }

    async saveDeviceID(strDeviceID: string): Promise<boolean> {
        let result = true;
        await LocalstorageHelper.setStorageItem(
            'DEVICE_ID',
            strDeviceID,
            undefined,
            false
        ).catch(() => result = false);
        return result;
    }

    async getDeviceID(): Promise<string> {
        return (await LocalstorageHelper.getStorageItem('DEVICE_ID', undefined, false)) as string || '';
    }

    async clearDeviceID() {
        return await LocalstorageHelper.removeStorageItem('DEVICE_ID', undefined, false);
    }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutoCompleteExternalOptions, FilledButtonComponent, Filter, FilterOperators, FindValuesResponse, GenericServiceResponse, ModalService, NTSTranslatePipe, ToastMessage, ToastMessageService, ToastMessageType } from '@nts/std';
import { TenantsActivationApiClient } from '../../api-clients/tenants-activation.api-client';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { MODULE_DOMAIN_MODEL_FULL_NAME } from 'src/app/module/module.module';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormDataService, STEPS } from '../../services/form-data.service';
import { UploadResponseDto } from '../../dto/upload-response.dto';
import { TENANTS_ACTIVATION_FULL_PATH } from '../../tenants-activation.module';
import { Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

export interface SelectedModuleInterface {
    identity: string;
    description: string;
    all: any;
}

export interface SelectModuleDataInterface {
    selectedModule?: SelectedModuleInterface;
    uploadResponse?: UploadResponseDto
}

@UntilDestroy()
@Component({
    selector: 'nts-select-module',
    templateUrl: './select-module.component.html',
    styleUrls: ['./select-module.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NTSTranslatePipe,
        FilledButtonComponent,
        FormsModule,
        NgSelectModule,
        CommonModule,
        SvgIconComponent
    ]
})
export class SelectModuleComponent implements OnInit {

    private currentStep = STEPS.selectModule;

    @ViewChild('input', { static: false }) inputRef: NgSelectComponent;

    public currentData: SelectModuleDataInterface;
    public selectedItem: SelectedModuleInterface = null;
    public itemInput$ = new Subject<string>();
    public lastInputText: string;
    public items$: Observable<SelectedModuleInterface[]>;
    public itemLoading = false;
    public noResult = false;
    public isOpen = false;
    public files = null;
    public uploading = false;
    public appendTo = 'body';
    private resizeEvent = null;

    constructor(
        private apiClient: TenantsActivationApiClient,
        private toastMessageService: ToastMessageService,
        private formDataService: FormDataService,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {

    }

    async uploadData() {
        this.uploading = true;
        this.cd.detectChanges();

        const fileToUpload = this.files[0];
        let response: GenericServiceResponse<UploadResponseDto> = null;

        const code = this.selectedItem?.identity ?? null;

        await this.apiClient.uploadData(fileToUpload, code).pipe(
            map((httpEvent: HttpEvent<GenericServiceResponse<UploadResponseDto>>) => {
                if (httpEvent) {
                    if (httpEvent.type === HttpEventType.UploadProgress) {
                        console.log(Math.round(100 * httpEvent.loaded / httpEvent.total));
                        // this.cd.detectChanges(); //TODO
                    } else if (httpEvent.type === HttpEventType.Response) {
                        response = httpEvent.body;
                    }
                }
            })).toPromise();
        this.uploading = false;

        this.currentData.uploadResponse = response?.operationSuccedeed ? response.result : null;
        this.formDataService.setData<UploadResponseDto>(this.currentStep, this.currentData.uploadResponse);

        if (response?.operationSuccedeed) {
            this.router.navigate([`${TENANTS_ACTIVATION_FULL_PATH}/${STEPS.operationComplete}`]);
        } else if (response?.operationSuccedeed === false) {
            this.toastMessageService.showToastsFromResponse(response);
        } else {
            const toastMessage: ToastMessage = {
                message: 'C\'è stato un problema, riprova',
                title: 'Attenzione',
                type: ToastMessageType.warn
            }
            this.toastMessageService.showToast(toastMessage);
        }
        this.cd.detectChanges();
    }

    async ngOnInit() {

        this.currentData = {};
        this.formDataService.setData<UploadResponseDto>(this.currentStep, null);
        this.loadItems();
    }

    async onFileInputChange(event: Event) {
        this.files = (event.target as HTMLInputElement).files;
    }

    public keyDownFn(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            this.inputRef.blurEvent.next(null);
        } else if (event.key === 'Del') {
            // TODO se si trova alla poszione zero con il cursore ed esiste un modello cancellare il modello
            // if (this.inputRef.)
        }
        return true;
    }

    public async onSelectedItem(selectedItem: { description: string, identity: any, all: any }) {
        if (selectedItem && selectedItem.identity != null) {
            this.selectedItem = selectedItem;
            this.currentData.selectedModule = selectedItem;
            this.noResult = false;
            this.itemLoading = false;
            this.cd.detectChanges();
        }
        this.lastInputText = '';
        this.cd.detectChanges();
    }

    private loadItems() {

        this.itemInput$.pipe(
            untilDestroyed(this),
            distinctUntilChanged()
        ).subscribe((term) => {
            if (term != null) {
                this.lastInputText = term;
            }
        });

        const autoCompleteOptions = new AutoCompleteExternalOptions();
        autoCompleteOptions.fullRootModelName = MODULE_DOMAIN_MODEL_FULL_NAME;
        autoCompleteOptions.outputProperties = ['Code', 'Title'];
        autoCompleteOptions.propertySearchList = ['Code', 'Title'];

        const isDisabledFilter = new Filter();
        isDisabledFilter.name = 'IsDisabled';
        isDisabledFilter.operator = FilterOperators.Equals;
        isDisabledFilter.value = false;

        autoCompleteOptions.additionalFilters = [isDisabledFilter];

        this.items$ = concat(
            of([]), // default items
            this.itemInput$.pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                tap(() => this.itemLoading = true),
                switchMap(
                    (term) => {
                        if (
                            term?.length > 0 &&
                            this.selectedItem?.identity &&
                            term === this.selectedItem?.identity?.toString() &&
                            this.selectedItem?.description != null ||
                            (this.lastInputText === this.selectedItem?.identity?.toString())
                        ) {
                            this.noResult = false;
                            this.itemLoading = false;
                            this.cd.detectChanges();
                            return of([
                                this.selectedItem
                            ]);
                        } else if (term?.length >= 3) {
                            autoCompleteOptions.searchValue = term;
                            return this.apiClient.autoComplete(autoCompleteOptions).pipe(
                                catchError((err) => {
                                    this.noResult = true;
                                    this.itemLoading = false;
                                    this.cd.detectChanges();
                                    this.toastMessageService.showToastsFromResponse(err);
                                    return of([]);
                                }), // empty list on error
                                map(
                                    (data: FindValuesResponse) => {
                                        if (data.operationSuccedeed == false) {
                                            this.toastMessageService.showToastsFromResponse(data);
                                        }
                                        return data && data.result && data.result.map((d) => this.parseAutocompleteDataFromPropertyArray(d)) || []
                                    }
                                ),
                                tap(
                                    (data) => {
                                        this.noResult = data.length === 0;
                                        this.itemLoading = false;
                                        this.cd.detectChanges();

                                    }
                                )
                            );
                        } else {
                            this.noResult = true;
                            this.itemLoading = false;
                            this.cd.detectChanges();
                            return of([]);
                        }
                    }
                )
            )
        );
    }

    private getAbsPosition(el) {
        let el2 = el;
        let curtop = 0;
        let curleft = 0;
        if (document.getElementById || document.all) {
            do {
                curleft += el.offsetLeft - el.scrollLeft;
                curtop += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
                el2 = el2.parentNode;
                while (el2 != el) {
                    curleft -= el2.scrollLeft;
                    curtop -= el2.scrollTop;
                    el2 = el2.parentNode;
                }
            } while (el.offsetParent);

        } else if ((document as any).layers) {
            curtop += el.y;
            curleft += el.x;
        }
        return [curtop, curleft];
    }

    public onDropdownOpen() {
        this.isOpen = true;
        this.zone.runOutsideAngular(() => {
            setTimeout(() => this.updatePosition());
            this.resizeEvent = window.addEventListener('resize', () => {
                this.updatePosition();
            });
        });
    }

    public onDropdownClose() {
        this.isOpen = false;
        if (this.resizeEvent) {
            this.resizeEvent();
        }
    }

    public trackByFn(item: SelectedModuleInterface) {
        return item.identity;
    }

    private updatePosition() {
        const dropdownElement = this.inputRef.dropdownPanel?.scrollElementRef.nativeElement.parentElement;
        if (dropdownElement) {
            const [top, left] = this.getAbsPosition(this.inputRef.element);
            const dropdownWidth = Math.max(this.inputRef.element.clientWidth, 280);
            const appendToElement = document.querySelector(this.appendTo) as HTMLElement;
            const mobileScrollableElement = document.querySelector('.mobile-scrollable-content');

            const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

            if ((left + dropdownWidth) > appendToElement.clientWidth) {
                dropdownElement.style.left =
                    left - (this.inputRef.element.clientWidth > 280 ? 0 : 280 - this.inputRef.element.clientWidth) - appendToElement.offsetLeft + 'px';
            } else {
                dropdownElement.style.left = left + 'px';
            }

            const topOffset = viewportHeight - appendToElement.clientHeight - appendToElement.scrollTop;

            dropdownElement.style.top = top + this.inputRef.element.clientHeight - topOffset + 'px';
            dropdownElement.style.width = this.inputRef.element.clientWidth + 'px';
            dropdownElement.style.display = 'unset';
        }
    }

    private parseAutocompleteDataFromPropertyArray(properties: any[]): SelectedModuleInterface {
        return {
            identity: properties[0],
            description: `${properties[0]} - ${properties[1]}`,
            all: null
        };
    }
}

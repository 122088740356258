import {
    IdentityTypeDecorator, ExternalDomainModelTypeDecorator,
    ExternalDomainModelTypeNameDecorator, StringPropertyViewModel, OrderByType, OrderBy
} from '@nts/std';
import { ExternalViewModel } from '@nts/std';
import { AutoCompleteExternalOptions } from '@nts/std';
import { LanguageIdentity } from './language.identity';
import { Language } from './language';

@IdentityTypeDecorator(LanguageIdentity)
@ExternalDomainModelTypeDecorator(Language)
@ExternalDomainModelTypeNameDecorator('Language')
export class LanguageExtViewModel extends ExternalViewModel<Language, LanguageIdentity> {

    // tslint:disable-next-line: variable-name
    private _languageCode: StringPropertyViewModel;

    public get languageCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._languageCode = value; }, this._languageCode, 'languageCode');
    }

    // tslint:disable-next-line: variable-name
    private _displayName: StringPropertyViewModel;

    public get displayName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._displayName = value; }, this._displayName, 'displayName');
    }

    // tslint:disable-next-line: variable-name
    private _nativeName: StringPropertyViewModel;

    public get nativeName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._nativeName = value; }, this._nativeName, 'nativeName');
    }

    // tslint:disable-next-line: variable-name
    private _englishName: StringPropertyViewModel;

    public get englishName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._englishName = value; }, this._englishName, 'englishName');
    }

    // tslint:disable-next-line: variable-name
    private _iso3LanguageName: StringPropertyViewModel;

    public get iso3LanguageName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._iso3LanguageName = value; }, this._iso3LanguageName, 'iso3LanguageName');
    }

    public override async postInit(): Promise<void> {
        await super.postInit()

        this.minSearchLength = 2;
        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.propertySearchList = ['LanguageCode', 'DisplayName', 'EnglishName', 'NativeName', 'Iso3LanguageName'];
        this.autoCompleteOptions.orderByPropertyNames = [
            new OrderBy({ propertyName: 'DisplayName', sortType: OrderByType.Ascending })
        ];
        this.autoCompleteOptions.outputProperties = ['LanguageCode', 'DisplayName', 'EnglishName', 'NativeName', 'Iso3LanguageName'];
    }
}

import { RootModelTypeNameDecorator, ActionApiClient, GenericServiceResponse } from '@nts/std';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { UploadResponseDto } from '../dto/upload-response.dto';

@Injectable()
@RootModelTypeNameDecorator('TenantsActivation')
export class TenantsActivationApiClient extends ActionApiClient {

    public uploadData(fileToUpload: any, moduleCode?: string): Observable<GenericServiceResponse<UploadResponseDto> | HttpEvent<GenericServiceResponse<UploadResponseDto>>> {

        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        if (moduleCode && moduleCode.length) {
            formData.append('moduleCode', moduleCode);
        }
        return this.getWebApiServiceAgent().invokePostUploadFile<any>('UploadData', formData, Object);
    }
}

import { CurrentSessionService, StdModule, ModalService, NtsAppModule, WebApiServiceAgent, ComponentLocator } from '@nts/std';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantsActivationApiClient } from './api-clients/tenants-activation.api-client';
import { TenantsActivationContainerComponent } from './components/tenants-activation-container/tenants-activation-container.component';
import { SelectModuleComponent } from './components/select-module/select-module.component';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { OperationCompleteComponent } from './components/operation-complete/operation-complete.component';
import { FormDataService, STEPS } from './services/form-data.service';
import { TenantsActivationGuard } from './services/tenants-activation-guard.service';
import { tenantsActivationIcons } from '../svg/tenants-activation';

export const TENANTS_ACTIVATION_FULL_PATH = 'manage/tenantsactivation';

const routes: Routes = [
  {
    path: '',
    component: TenantsActivationContainerComponent,
    children: [
      { path: STEPS.selectModule, component: SelectModuleComponent },
      { path: STEPS.operationComplete, component: OperationCompleteComponent, canActivate: [TenantsActivationGuard] },
      { path: '', redirectTo: STEPS.selectModule, pathMatch: 'full' },
      { path: '**', redirectTo: STEPS.selectModule },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(
      routes
    ),
  ],
  declarations: [],
  providers: [
    provideSvgIcons([...tenantsActivationIcons]),
    {
      provide: TenantsActivationApiClient,
      deps: [CurrentSessionService, WebApiServiceAgent]
    },
    FormDataService,
    TenantsActivationGuard
  ]
})
export class TenantsActivationModule extends NtsAppModule {

  constructor(
    componentLocator: ComponentLocator) {

    super(
      componentLocator
    );
  }

  registerAll(
    modalService: ModalService
  ) {

  }
}

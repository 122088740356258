import { LogService } from "@nts/std/src/lib/utility";
import { IdentityTypeInspector } from "../decorators/identity-type.decorator";
import { LongOpParametersTypeInspector } from "../decorators/long-op-parameters-type.decorator";
import { LongOpResultTypeInspector } from "../decorators/long-op-result-type.decorator";
import { BaseLongOpModel } from "../domain-models/base-long-op-model";
import { BaseValidator } from "../domain-models/decorators/commons/base-validator";
import { IdentityPropertyListInspector } from "../domain-models/decorators/identity-property-list.decorator";
import { IdentityTypeNameInspector } from "../domain-models/decorators/identity-type-name.decorator";
import { ModelFullNameInspector } from "../domain-models/decorators/model-full-name.decorator";
import { ModelPropertyListInspector } from "../domain-models/decorators/model-property-list.decorator";
import { ModelTypeNameInspector } from "../domain-models/decorators/model-type-name.decorator";
import { ModelTypeInspector } from "../domain-models/decorators/model-type.decorator";
import { ClassType } from "../serialization/class-transformer/ClassTransformer";
import { TypeMetadata } from "../serialization/class-transformer/metadata/TypeMetadata";
import { defaultMetadataStorage } from "../serialization/class-transformer/storage";
import { AggregateMetaData } from "./aggregate-meta-data";
import { BoolMetaData } from "./bool-meta-data";
import { DateTimeMetaData } from "./date-time-meta-data";
import { DomainModelMetaData, ExternalMetaData, InternalCollectionMetaData, InternalRelationMetaData } from "./domain-model-meta-data";
import { EnumMetaData } from "./enum-meta-data";
import { NumericMetaData } from "./numeric-meta-data";
import { PropertyMetaData } from "./property-meta-data";
import { StringMetaData } from "./string-meta-data";

export class MetaDataFactory {
    static buildAggregateMetaDataFromClass<TClass>(classType: ClassType<TClass>, classInstance: TClass): AggregateMetaData {
  
        // console.log('buildAggregateMetaDataFromClass', classType);
        const aggregateMetaData = new AggregateMetaData();
        let className = null;
        if (ModelTypeNameInspector.isApplied(classType)) {
          className = ModelTypeNameInspector.getValue(classType);
          aggregateMetaData.rootName = className;
        } else {
          LogService.log(classType.constructor.name + ' non ha il decoratore ' + ModelTypeNameInspector.DECORATOR_NAME);
          return null;
        }
        if (ModelFullNameInspector.isApplied(classType)) {
          aggregateMetaData.rootFullName = ModelFullNameInspector.getValue(classType);
        } else {
          LogService.log(className + ' non ha il decoratore ' + ModelFullNameInspector.DECORATOR_NAME);
        }
        aggregateMetaData.rootMetaData = this.buildDomainModelMetaDataFromClass(classType, classInstance);
        aggregateMetaData.domainModels = [aggregateMetaData.rootMetaData];
    
        this.recursiveAddDomainModelMetaData(aggregateMetaData, aggregateMetaData.rootMetaData);
    
        return aggregateMetaData;
    }
    
    private static recursiveAddDomainModelMetaData(aggregateMetaData: AggregateMetaData, domainModelMetaData: DomainModelMetaData) {
        if (domainModelMetaData.internalCollections?.length > 0) {
    
          for (const dmm of [...domainModelMetaData.internalCollections.map((c: InternalCollectionMetaData) => c.dependentMetaData)]) {
            if (!aggregateMetaData.domainModels.find((d) => d.name === dmm.name)) {
              aggregateMetaData.domainModels.push(dmm);
              this.recursiveAddDomainModelMetaData(aggregateMetaData, dmm);
            }
          }
    
        }
    
        if (domainModelMetaData.internalRelations?.length > 0) {
    
          for (const dmm of [...domainModelMetaData.internalRelations.map((c: InternalRelationMetaData) => c.dependentMetaData)]) {
            if (!aggregateMetaData.domainModels.find((d) => d.name === dmm.name)) {
              aggregateMetaData.domainModels.push(dmm);
              this.recursiveAddDomainModelMetaData(aggregateMetaData, dmm);
            }
          }
        }
    }
  
    static buildDomainModelMetaDataFromClass<TClass>(classType: ClassType<TClass>, classInstance: TClass): DomainModelMetaData {
          const dmm = new DomainModelMetaData();
      
          let className = null;
      
          if (ModelTypeNameInspector.isApplied(classType)) {
            className = ModelTypeNameInspector.getValue(classType);
            dmm.name = className;
          } else {
            LogService.log(classType.constructor.name + ' non ha il decoratore ' + ModelTypeNameInspector.DECORATOR_NAME);
            return null;
          }
      
          let identityType = null;
      
          if (IdentityTypeInspector.isApplied(classType)) {
            identityType = IdentityTypeInspector.getValue(classType);
      
            let identityTypeName = identityType;
      
            if (IdentityTypeNameInspector.isApplied(identityType)) {
              identityTypeName = IdentityTypeNameInspector.getValue(identityType);
            } else {
              LogService.log(identityTypeName + ' non ha il decoratore ' + IdentityTypeNameInspector.DECORATOR_NAME);
            }
      
            if (IdentityPropertyListInspector.isApplied(identityType)) {
              dmm.identityNames = IdentityPropertyListInspector.getValue(identityType);
            } else {
              LogService.log(identityTypeName + ' non ha il decoratore ' + IdentityPropertyListInspector.DECORATOR_NAME);
            }
      
          } else {
            LogService.log(className + ' non ha il decoratore ' + IdentityTypeInspector.DECORATOR_NAME);
          }
      
          if (ModelPropertyListInspector.isApplied(classType)) {
            dmm.allPropertyNames = ModelPropertyListInspector.getValue(classType);
          } else {
            LogService.log(className + ' non ha il decoratore ' + ModelPropertyListInspector.DECORATOR_NAME);
            return null;
          }
      
          for (const propertyName of dmm.allPropertyNames) {
            const propertyMetaData = BaseValidator.getPropertyMetaData(classType, propertyName);
            if (!propertyMetaData) {
              LogService.log('La property ' + propertyName + ' della classe ' + className + ' non ha il decoratore!');
            } else if (propertyMetaData instanceof PropertyMetaData) {
              switch (propertyMetaData.getType()) {
                case 'Numeric':
                  dmm.numerics.push(propertyMetaData as NumericMetaData);
                  dmm.propertyNames.push(propertyName);
                  break;
                case 'String':
                  dmm.strings.push(propertyMetaData as StringMetaData);
                  dmm.propertyNames.push(propertyName);
                  break;
                case 'DateTime':
                  dmm.dateTimes.push(propertyMetaData as DateTimeMetaData);
                  dmm.propertyNames.push(propertyName);
                  break;
                case 'Enum':
                  dmm.enums.push(propertyMetaData as EnumMetaData);
                  dmm.propertyNames.push(propertyName);
                  break;
                case 'Bool':
                  dmm.bools.push(propertyMetaData as BoolMetaData);
                  dmm.propertyNames.push(propertyName);
                  break;
                default:
                  LogService.log('La property ' + propertyName + ' della classe ' + className + ' di tipo ' + propertyMetaData.getType() + ' non è gestita!')
                  break;
              }
            } else if (propertyMetaData instanceof InternalRelationMetaData) {
              if ('result' === propertyName && classInstance instanceof BaseLongOpModel) {
                const dependentClassType = LongOpResultTypeInspector.getValue(classType);
                propertyMetaData.dependentMetaData = this.buildDomainModelMetaDataFromClass(dependentClassType, new dependentClassType());
                dmm.internalRelations.push(propertyMetaData);
              } else if ('params' === propertyName && classInstance instanceof BaseLongOpModel) {
                const dependentClassType = LongOpParametersTypeInspector.getValue(classType);
                propertyMetaData.dependentMetaData = this.buildDomainModelMetaDataFromClass(dependentClassType, new dependentClassType());
                dmm.internalRelations.push(propertyMetaData);
              } else {
                const typeMetaData: TypeMetadata = defaultMetadataStorage.findTypeMetadata(classType, propertyName);
                if (!typeMetaData) {
                  LogService.log('La property ' + propertyName + ' della classe ' + className + ' non ha il decoratore @Type');
                } else {
                  const reflectedType = typeMetaData.typeFunction() as ClassType<any>;
                  propertyMetaData.dependentMetaData = this.buildDomainModelMetaDataFromClass(reflectedType, new reflectedType());
                  dmm.internalRelations.push(propertyMetaData);
                }
              }
            } else if (propertyMetaData instanceof InternalCollectionMetaData) {
      
              const typeMetaData: TypeMetadata = defaultMetadataStorage.findTypeMetadata(classType, propertyName);
              if (!typeMetaData) {
                LogService.log('La property ' + propertyName + ' della classe ' + className + ' non ha il decoratore @Type');
              } else {
                const reflectedType = typeMetaData.typeFunction() as ClassType<any>;
      
                let itemModelType = null;
                if (ModelTypeInspector.isApplied(reflectedType)) {
                  itemModelType = ModelTypeInspector.getValue(reflectedType);
      
                  propertyMetaData.dependentMetaData = this.buildDomainModelMetaDataFromClass(itemModelType, new itemModelType());
                  dmm.internalCollections.push(propertyMetaData);
                } else {
                  LogService.log(reflectedType?.constructor?.name + ' non ha il decoratore ' + ModelTypeInspector.DECORATOR_NAME);
                }
              }
      
            } else if (propertyMetaData instanceof ExternalMetaData) {
      
              const typeMetaData: TypeMetadata = defaultMetadataStorage.findTypeMetadata(classType, propertyName);
              if (!typeMetaData) {
                LogService.log('La property ' + propertyName + ' della classe ' + className + ' non ha il decoratore @Type');
              } else {
                const reflectedType = typeMetaData.typeFunction() as ClassType<any>;
                propertyMetaData.dependentAggregateMetaData = this.buildAggregateMetaDataFromClass(reflectedType, new reflectedType());
                dmm.externals.push(propertyMetaData);
              }
      
            } else {
              LogService.log('La property ' + propertyName + ' della classe ' + className + ' non è gestita!', propertyMetaData)
            }
          }
      
          return dmm;
    }
}
import { ItemViewModelTypeDecorator } from '@nts/std';
import { MultiLanguageCollectionViewModel } from '@nts/std';
import { ModuleML } from '../domain-models/module-ml';
import { ModuleMLIdentity } from '../domain-models/module-ml.identity';
import { ModuleMLViewModel } from './module-ml.view-model';


@ItemViewModelTypeDecorator(ModuleMLViewModel)
export class ModuleMLCollectionViewModel extends MultiLanguageCollectionViewModel<ModuleMLViewModel, ModuleML, ModuleMLIdentity> {
    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, ModuleMLCollectionViewModel.prototype);
    }
}


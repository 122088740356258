import { DateTime, Expose, Transform } from '@nts/std';

export class UsageReportFiltersDto {

    @Expose()
    @Transform((value, obj, type) => {
        return DateTime.transform('fromDate', value, obj, type);
    })
    fromDate: Date;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTime.transform('toDate', value, obj, type);
    })
    toDate: Date;
}

import { CustomPropertyViewModelDecorator, DateDecorator, DateTimeMetaData, DateTimePropertyViewModel, PropertyViewModelFactory, ServiceResponse, ToastMessageService } from "@nts/std";
import { LicenseReportApiClient } from "../api-clients/license-report.api-client";
import { BehaviorSubject, lastValueFrom, map } from "rxjs";
import { UsageReportFiltersDto } from "../dto/usage-report-filters.dto";
import { HttpEvent, HttpEventType } from "@angular/common/http";

export class LicenseReportViewModel {

    downloading = new BehaviorSubject<boolean>(false);

    constructor(
        private apiClient: LicenseReportApiClient,
        private toastMessageService: ToastMessageService,
    ) {

    }

    // tslint:disable-next-line: member-ordering variable-name
    private _fromDate: DateTimePropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @DateDecorator({ displayNameKey: 'LicenseReport_FromDate_DisplayName', isRequired: true })
    public get fromDate(): DateTimePropertyViewModel {
        if (this._fromDate == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<DateTimeMetaData>(
                this, 'fromDate', null, this, null, null, null, false, true
            );
            this._fromDate = new DateTimePropertyViewModel(init);
        }
        return this._fromDate;
    }

    // tslint:disable-next-line: member-ordering variable-name
    private _toDate: DateTimePropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @DateDecorator({ displayNameKey: 'LicenseReport_ToDate_DisplayName', isRequired: true })
    public get toDate(): DateTimePropertyViewModel {
        if (this._toDate == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<DateTimeMetaData>(
                this, 'toDate', null, this, null, null, null, false, true
            );
            this._toDate = new DateTimePropertyViewModel(init);
        }
        return this._toDate;
    }

    async downloadCsv() {


        this.fromDate.isEnabled = false;
        this.toDate.isEnabled = false;
        this.downloading.next(true);

        try {

            const dto = new UsageReportFiltersDto();
            dto.fromDate = this.fromDate.value;
            dto.toDate = this.toDate.value;

            const response = await lastValueFrom(this.apiClient.getAzureUsageReport(dto)
                .pipe(map((httpEvent: HttpEvent<Blob>) => {
                    if (httpEvent) {
                        if (httpEvent.type === HttpEventType.DownloadProgress) {
                            //this.progressBarValue = Math.round(100 * httpEvent.loaded / httpEvent.total);
                            // this.cd.detectChanges(); //TODO
                        } else if (httpEvent.type === HttpEventType.Response) {
                            const blob = httpEvent.body;
                            let fileName = 'file.csv';
                            if (httpEvent.headers.has("content-disposition")) {
                                const contentDisposition = httpEvent.headers.get("content-disposition");
                                const contentDispositionSplitted = contentDisposition.split(';');
                                const fileNameExtended = contentDispositionSplitted[1].split('filename=');
                                fileName = fileNameExtended[1].replace(/\"/g, '').trim().split(',').join('');
                            }
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = fileName;
                            link.click();
                        }
                    }
                    return httpEvent;
                }))
            )

            if (response instanceof ServiceResponse) {
                this.toastMessageService.showToastsFromResponse(response);
            }

        } catch (err) {
            console.log(err);
        } finally {
            this.fromDate.isEnabled = true;
            this.toDate.isEnabled = true;
            this.downloading.next(false);
        }

    }
}

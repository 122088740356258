<div class="main-container">

    <div class="select-tenant-container">

        <div class="select-tenant-header">
            {{ "TenantsActivation_SelectModule_Header" | NTSTranslate }}
        </div>

        <div class="select-tenant-content">

            <ul>
                <li>
                    <span class="detail-logo">
                        <svg-icon key="toc-black-24-dp" fontSize="24px"></svg-icon>
                    </span>
                    <div class="autocomplete-container">

                        <div class="autocomplete-components">
                            <div class="autocomplete-input">
                                <ng-select [appendTo]="appendTo" #input [loading]="itemLoading"
                                    placeholder="{{'TenantsActivation_SelectModule_FindModule_Placeholder' | NTSTranslate}}"
                                    (open)="onDropdownOpen()" (close)="onDropdownClose()"
                                    (clear)="input.close(); onSelectedItem($event)" (change)="onSelectedItem($event)"
                                    notFoundText="{{'TenantsActivation_SelectModule_FindModule_NotFound' | NTSTranslate}}"
                                    [multiple]="false"
                                    clearAllText="{{'TenantsActivation_SelectModule_FindModule_Clear' | NTSTranslate}}"
                                    loadingText="{{'TenantsActivation_SelectModule_FindModule_Loading' | NTSTranslate}}"
                                    dropdownPosition="bottom" [keyDownFn]="keyDownFn.bind(this)" [trackByFn]="trackByFn"
                                    [items]="items$ | async" [minTermLength]="0" [typeahead]="itemInput$"
                                    [(ngModel)]="selectedItem"
                                    typeToSearchText="{{'TenantsActivation_SelectModule_FindModule_Three_Or_More_Chars' | NTSTranslate}}"
                                    bindLabel="description" [selectOnTab]="true">

                                </ng-select>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <span class="detail-logo">
                        <svg-icon key="upload-file-black-24-dp" fontSize="24px"></svg-icon>
                    </span>
                    <div class="form-group">
                        <input type="file" id="file" (change)="onFileInputChange($event)">
                    </div>
                </li>

                <li>
                    <span class="detail-logo">
                        <svg-icon key="info-black-24-dp" fontSize="24px"></svg-icon>
                    </span>
                    <span>{{'TenantActivations_InfoMessage' | NTSTranslate}}</span>
                </li>
            </ul>
        </div>

        <div class="select-tenant-footer">
            <nts-filled-button [type]="3" [title]="'TenantsActivation_SelectModule_Activate' | NTSTranslate"
                [label]="'TenantsActivation_SelectModule_Activate' | NTSTranslate" (onClicked)="uploadData()"
                [icon]="uploading ? 'refresh-animated': 'check'" [isLoading]="uploading"
                [isDisabled]="files && files[0] != null && !uploading ? null : true">
            </nts-filled-button>

        </div>

    </div>

</div>

import { ToolBarViewModel } from '@nts/std';
import { MODULE_DOMAIN_MODEL_FULL_NAME, MODULE_TENANTS_DOMAIN_MODEL_FULL_NAME } from 'src/app/app-routing.module';
export class ModuleRelatedToolBarViewModel extends ToolBarViewModel {

    protected override async addRelatedCommands() {
        this.addRelatedClientCommand(MODULE_DOMAIN_MODEL_FULL_NAME, 'Module', true);
        this.addRelatedClientCommand(MODULE_TENANTS_DOMAIN_MODEL_FULL_NAME, 'ModuleTenants');
    }
}


import { SoftwareService } from 'src/app/software-service/domain-models/software-service';
import {
    IdentityTypeDecorator, ModelTypeNameDecorator, Expose, OCCModel,
    MultiLanguageHostInterface, Type, StringDecorator, BoolDecorator
} from '@nts/std';
import { DefaultActivationModes } from './enum/default-activation-modes.enum';
import { SellModes } from './enum/sell-modes.enum';
import { ModuleML } from './module-ml';
import { ModuleMLCollection } from './module-ml.collection';
import { ModuleMLIdentity } from './module-ml.identity';
import { ModuleIdentity } from './module.identity';
import { ModuleTypes } from './enum/module-types';


@IdentityTypeDecorator(ModuleIdentity)
@ModelTypeNameDecorator('Module')
export class Module extends OCCModel<ModuleIdentity> implements MultiLanguageHostInterface<ModuleML, ModuleMLIdentity, ModuleMLCollection> {

    // tslint:disable-next-line: variable-name
    private _code: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Module_Code', maxLength: 20, isRequired: true })
    public get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _title: string;


    @Expose()
    @StringDecorator({ displayNameKey: 'ModuleML_Title', maxLength: 40, isRequired: false })
    public get title(): string {
        return this.getValue<string>(() => this._title, 'title');
    }
    public set title(value: string) {
        this.setValue<string>(() => { this._title = value; }, value, 'title');
    }

    // tslint:disable-next-line: variable-name
    private _isDisabled: boolean;


    @Expose()
    @BoolDecorator({ displayNameKey: 'Module_IsDisabled' })
    public get isDisabled(): boolean {
        return this.getValue<boolean>(() => this._isDisabled, 'isDisabled');
    }
    public set isDisabled(value: boolean) {
        this.setValue<boolean>(() => { this._isDisabled = value; }, value, 'isDisabled');
    }

    // tslint:disable-next-line: variable-name
    protected _sellMode: SellModes;

    @Expose()
    public get sellMode(): SellModes {
        return this.getValue<SellModes>(() => this._sellMode, 'sellMode');
    }
    public set sellMode(value: SellModes) {
        this.setValue<SellModes>(() => { this._sellMode = value; }, value, 'sellMode');
    }

    // tslint:disable-next-line: variable-name
    protected _moduleType: ModuleTypes;

    @Expose()
    public get moduleType(): ModuleTypes {
        return this.getValue<ModuleTypes>(() => this._moduleType, 'moduleType');
    }
    public set moduleType(value: ModuleTypes) {
        this.setValue<ModuleTypes>(() => { this._moduleType = value; }, value, 'moduleType');
    }

    // tslint:disable-next-line: variable-name
    protected _defaultActivationMode: DefaultActivationModes;

    @Expose()
    public get defaultActivationMode(): DefaultActivationModes {
        return this.getValue<DefaultActivationModes>(() => this._defaultActivationMode, 'defaultActivationMode');
    }
    public set defaultActivationMode(value: DefaultActivationModes) {
        this.setValue<DefaultActivationModes>(() => { this._defaultActivationMode = value; }, value, 'defaultActivationMode');
    }

    // tslint:disable-next-line: variable-name
    private _multiLanguageDescriptions: ModuleMLCollection;

    @Expose()
    @Type(() => ModuleMLCollection)
    public get multiLanguageDescriptions(): ModuleMLCollection {
        return this.getValueForInternalCollection<ModuleMLCollection, ModuleML>(
            (value) => {
                this._multiLanguageDescriptions = value;
            },
            this._multiLanguageDescriptions,
            'multiLanguageDescriptions',
            ModuleMLCollection
        );
    }

    public set multiLanguageDescriptions(value: ModuleMLCollection) {
        this.setValueForInternalCollection<ModuleMLCollection, ModuleML>(() => {
            this._multiLanguageDescriptions = value;
        }, value, 'multiLanguageDescriptions');
    }

    // tslint:disable-next-line: variable-name
    private _softwareServiceCode: string;

    @Expose()
    public get softwareServiceCode(): string {
        return this.getValue<string>(() => this._softwareServiceCode, 'softwareServiceCode');
    }
    public set softwareServiceCode(value: string) {
        this.setValue<string>(() => { this._softwareServiceCode = value; }, value, 'softwareServiceCode');
    }


    // tslint:disable-next-line: variable-name
    private _softwareServiceRef: SoftwareService;

    // tslint:disable-next-line: max-line-length
    @Expose()
    @Type(() => SoftwareService)
    public get softwareServiceRef(): SoftwareService {
        return this.getValueForExternal<SoftwareService>(
            () => this._softwareServiceRef,
            'softwareServiceRef',
            SoftwareService
        );
    }
    public set softwareServiceRef(value: SoftwareService) {
        this.setValueForExternal(() => {
            this._softwareServiceRef = value;
        }, value, 'softwareServiceRef');
    }
}

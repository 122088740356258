import 'reflect-metadata';

const StringIsNumber = value => isNaN(Number(value)) === false;

export class ModelHelper {

    static enumToArray(oEnum) {
        return Object.keys(oEnum)
            .filter(StringIsNumber)
            .map(key => oEnum[key]);
    }

    static enumToOptions(oEnum) {
        return Object.keys(oEnum)
            .filter(StringIsNumber)
            .map(key => { return { label: oEnum[key], value: parseInt(key) } });
    }
}

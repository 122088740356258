import { RootModelTypeNameDecorator, RootModelTypeDecorator, CrudApiClient } from '@nts/std';
import { Injectable } from '@angular/core';
import { Module } from '../domain-models/module';
import { ModuleIdentity } from '../domain-models/module.identity';

@Injectable()
@RootModelTypeNameDecorator('Module')
@RootModelTypeDecorator(Module)
export class ModuleApiClient extends CrudApiClient<Module, ModuleIdentity> {

}

import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { TENANTS_ACTIVATION_FULL_PATH } from '../tenants-activation.module';
import { FormDataService, STEPS } from './form-data.service';

@Injectable()
export class TenantsActivationGuard implements CanActivate {
    constructor(private router: Router, private formDataService: FormDataService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const canActivate = this.formDataService.getData(STEPS.selectModule) != null;
        if (canActivate) {
            return true;
        }
        const baseUrl = `${TENANTS_ACTIVATION_FULL_PATH}/${STEPS.selectModule}`;
        this.router.navigate([baseUrl]);
        return false;
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ExternalViewModelInterface } from '../../../view-models/external-view-model.interface';
import { PropertyViewModelInterface } from '../../../view-models/property-view-model.interface';
import { BaseExternalPropertyTextBox } from '../core/base-external-property-text-box';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MetaDataUtils } from '../../../meta-data/meta-data-utils';
import { ExternalFieldInputMode, ExternalFieldMetaData } from '../../../layout-meta-data/external-field-meta-data';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { ExternalFieldComponent } from '../core/external-field/external-field.component';
import { merge, Subscription } from 'rxjs';
import { ExtAutocompleteTextBoxComponent } from '../core/ext-autocomplete-text-box/ext-autocomplete-text-box.component';
import { LabelBoxComponent } from '../core/base/label-box/label-box.component';
import { ExtNewAutocompleteTextBoxComponent } from '../core/ext-new-autocomplete-text-box/ext-new-autocomplete-text-box.component';

@UntilDestroy()
@Component({
    selector: 'nts-decode-text-box',
    templateUrl: './decode-text-box.component.html',
    styleUrls: ['./decode-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelBoxComponent,
        ExtAutocompleteTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent,
    ]
})
export class DecodeTextBoxComponent extends ExternalFieldComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * Property valorizzata solo dal layout custom, vincerà sul externalPropertyViewModel.isVisible solo se sarà impostata a false
     */
    @Input() override isHidden = false;

    @HostBinding('class.additional-field')
    @Input() override additionalField = false;

    @HostBinding('class.full-column')
    @Input() fullColumn = false;

    @Input() externalPropertyViewModel!: ExternalViewModelInterface;
    
    /**
     * @deprecated 
     * Utilizza [decodeProperties] per elencare la lista delle decode properties
     */
    @Input() decodeDescription?: PropertyViewModelInterface;

    /**
     * @deprecated 
     * Aggiungi in [decodeProperties] il codice se vuoi visualizzarlo
     */
    @Input() showCodeInDescription: boolean|null = null;    

    /**
     * @deprecated 
     * Il codice viene automaticamente recuperato dai metadati
     */
    @Input() code!: PropertyViewModelInterface;

    /**
     * Lista dei campi in Pascal Case, si possono anche passare i custom property view model (sempre in Pascal Case)
     */
    @Input() decodeProperties: string[] = null;

    /**
     * Lista dei campi dove effettuare la ricerca in Pascal Case
     */
    @Input() searchProperties: string[] = null;

    /**
     * Al momento supporta solo la stringa 'top', permette di impostare la label sopra il campo
     */
    @Input() labelPosition = '';
    
    @Input() isDisabled: boolean = false;
    @Input() tabIndex!: number;
    @Input() isLabelZoomEnabled = true;
    @Input() appendTo = 'app-root';
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() primaryColor = null;

    @Output() externalPropertyViewModelInitialized = new EventEmitter<void>();

    @ViewChild('textBox', { static: false }) set content(content: BaseExternalPropertyTextBox<any>) {
        this.textBox = content;
    }

    /**
     * Property calcolata in base alle due property: isHidden e externalPropertyViewModel.isVisible
     */
    @HostBinding('class.is-hidden')
    isHiddenCalculated = false;

    get isFullWidth(){
        return (this.labelPosition === 'top' ? true: false);
    }

    get name(): string {
        return MetaDataUtils.toCamelCase(this.externalPropertyViewModel.externalMetaData.principalPropertyName);
    }

    get path(): string {
        return this.externalPropertyViewModel.reservedPath;
    }

    get description(): string {
        return this.externalPropertyViewModel.externalMetaData.descriptions.description?.length > 0 ? this.externalPropertyViewModel.externalMetaData.descriptions.description : MessageResourceManager.Current.getMessage(this.externalPropertyViewModel.externalMetaData.descriptions.descriptionKey);
    }

    get displayName(): string {
        return this.externalPropertyViewModel.externalMetaData.descriptions.displayName?.length > 0 ? this.externalPropertyViewModel.externalMetaData.descriptions.displayName : MessageResourceManager.Current.getMessage(this.externalPropertyViewModel.externalMetaData.descriptions.displayNameKey);
    }

    get externalCodes(): ExternalFieldMetaData[] {
        const externalFieldCode = new ExternalFieldMetaData();
        externalFieldCode.inputMode = ExternalFieldInputMode.Autocomplete;
        externalFieldCode.name = this.code.propertyName;
        externalFieldCode.displayName = this.code.metadataShortDescription;
        externalFieldCode.path = '' //(this.path.length > 0 ? (this.path + '.') : '') + this.name;
        return [externalFieldCode];
    }

    get externalCodesAvailables(): string[] {
        return this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.map((s) => MetaDataUtils.toCamelCase(s));
    }

    get externalDecodes(): string[] {
        const propertyName: string|null = this.decodeDescription?.propertyName ?? null;
        if (propertyName && propertyName.length > 0) {
            return [propertyName];
        }
        return [];
        
    }

    get externalDecodesAvailables(): string[] {
        if (!this._externalDecodesAvailables) {
            this._externalDecodesAvailables = this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.strings.map((s) => MetaDataUtils.toCamelCase(s.name)).filter((s) => this.externalCodesAvailables.indexOf(s) === -1);
        }
        return this._externalDecodesAvailables;
    }
    
    protected propertyChangedSubscription: Subscription|null = null;

    private textBox!: BaseExternalPropertyTextBox<any>;
    private _externalDecodesAvailables!: string[];

    constructor(private readonly cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel for externalPropertyViewModel!'); }
        if (!this.code) { throw new Error('Missing viewModel for code!'); }
        let decodeProperties = null;
        if (this.decodeDescription) {

            if (this.decodeDescription?.isCustom === false) {
                decodeProperties =  [this.decodeDescription.propertyMetaData.name];
            } else {
                decodeProperties = [this.decodeDescription.propertyName];
            }
                       
        }
        if (this.decodeProperties?.length > 0) {
            decodeProperties = this.decodeProperties;
        }

        if (this.showCodeInDescription == null) {  
            this.showCodeInDescription = this.externalPropertyViewModel.showCode;
        }
        this.decodeProperties = decodeProperties;
    }

    ngOnChanges(changes: SimpleChanges) {
        
        if (changes['isHidden']) {
            if (changes['isHidden']?.currentValue === true) {
                this.isHiddenCalculated = true;
            } else {
              this.isHiddenCalculated = !this.externalPropertyViewModel?.isVisible;
            }
        } else if (changes['externalPropertyViewModel']) {
            this.unsubscribe();
            this.isHiddenCalculated = this.isHidden === true ? true : !this.externalPropertyViewModel.isVisible;

            this.propertyChangedSubscription = merge(
                this.externalPropertyViewModel.externalDomainModelChanged,
                this.externalPropertyViewModel.decodeCompleted,
                this.externalPropertyViewModel.propertyChanged
            ).subscribe(() => {
                this.cd.detectChanges();
            });
        
            this.externalPropertyViewModelInitialized.emit();
        }
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe() {
        if (this.propertyChangedSubscription != null) {
            this.propertyChangedSubscription.unsubscribe();
        }
    }

    onKeyDown(event: any) {
        if (event.ctrlKey && this.externalPropertyViewModel.isEnabled) {
            switch (event.key) {
                case 'F6':
                    this.textBox.zoom();
                    break;
                case 'F8':
                    this.textBox.startPresentation(event);
                    break;
            }
        }
    }

    openZoom() {
        if ((this.isDisabled || !this.code.isEnabled || this.externalPropertyViewModel.isEnabled === false) && this.externalPropertyViewModel?.securityAccess == null) {
            this.textBox.startPresentation(null, true, false);
            return;
        } 
        if (this.externalPropertyViewModel?.securityAccess == null) {
            this.textBox.zoom();
        }
    }    
}

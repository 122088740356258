
import {
  IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel,
} from '@nts/std';
import { TenantIdentity } from './tenant.identity';
import { Address } from './address';
import { TenantTypes } from './enum/tenant-types';
import { ContactDetail } from './contact-detail';
import { CompanyInformation } from './company-information';
import { SynchronizationData } from './synchronization-data';


@IdentityTypeDecorator(TenantIdentity)
@ModelTypeNameDecorator('Tenant')
export class Tenant extends OCCAuditDeactivableModel<TenantIdentity> {

  // tslint:disable-next-line: variable-name
  private _id: number;

  @Expose()
  public get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  public set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  // tslint:disable-next-line: variable-name
  private _businessName: string;

  @Expose()
  public get businessName(): string {
    return this.getValue<string>(() => this._businessName, 'businessName');
  }
  public set businessName(value: string) {
    this.setValue<string>(() => { this._businessName = value; }, value, 'businessName');
  }

  // tslint:disable-next-line: variable-name
  private _vatNumber: string;

  @Expose()
  public get vatNumber(): string {
    return this.getValue<string>(() => this._vatNumber, 'vatNumber');
  }
  public set vatNumber(value: string) {
    this.setValue<string>(() => { this._vatNumber = value; }, value, 'vatNumber');
  }

  // tslint:disable-next-line: variable-name
  private _taxNumber: string;

  @Expose()
  public get taxNumber(): string {
    return this.getValue<string>(() => this._taxNumber, 'taxNumber');
  }
  public set taxNumber(value: string) {
    this.setValue<string>(() => { this._taxNumber = value; }, value, 'taxNumber');
  }

  // tslint:disable-next-line: variable-name
  private _isLocked: boolean;

  @Expose()
  public get isLocked(): boolean {
    return this.getValue<boolean>(() => this._isLocked, 'isLocked');
  }
  public set isLocked(value: boolean) {
    this.setValue<boolean>(() => { this._isLocked = value; }, value, 'isLocked');
  }

  // tslint:disable-next-line: variable-name
  protected _tenantType;

  @Expose()
  public get tenantType(): TenantTypes {
    return this.getValue<TenantTypes>(() => this._tenantType, 'tenantType');
  }
  public set tenantType(value: TenantTypes) {
    this.setValue<TenantTypes>(() => { this._tenantType = value; }, value, 'tenantType');
  }

  // tslint:disable-next-line: variable-name
  private _partnerId: number;

  @Expose()
  public get partnerId(): number {
    return this.getValue<number>(() => this._partnerId, 'partnerId');
  }
  public set partnerId(value: number) {
    this.setValue<number>(() => { this._partnerId = value; }, value, 'partnerId');
  }

  // tslint:disable-next-line: variable-name
  private _partnerRef: Tenant;

  // @ExternalDecorator({ displayNameKey: 'Tenant_PartnerRef', principalPName1: 'partnerId', dependantPName1: 'id' })
  @Expose()
  @Type(() => Tenant)
  public get partnerRef(): Tenant {
    return this.getValueForExternal<Tenant>(
      () => this._partnerRef,
      'partnerRef',
      Tenant
    );
  }
  public set partnerRef(value: Tenant) {
    this.setValueForExternal(() => {
      this._partnerRef = value;
    }, value, 'partnerRef');
  }

  // tslint:disable-next-line: variable-name
  private _contactDetails: ContactDetail;

  @Expose()
  // @InternalDecorator({ displayNameKey: 'contactDetails' })
  @Type(() => ContactDetail)
  public get contactDetails(): ContactDetail {
    return this.getValueForInternal<ContactDetail>(
      (value) => {
        this._contactDetails = value;
      },
      this._contactDetails,
      'contactDetails',
      ContactDetail
    );
  }

  public set contactDetails(value: ContactDetail) {
    this.setValueForInternal(() => {
      this._contactDetails = value;
    }, value, 'contactDetails');
  }

  // tslint:disable-next-line: variable-name
  private _address: Address;

  @Expose()
  // @InternalDecorator({ displayNameKey: 'Indirizzo' })
  @Type(() => Address)
  public get address(): Address {
    return this.getValueForInternal<Address>(
      (value) => {
        this._address = value;
      },
      this._address,
      'address',
      Address
    );
  }

  public set address(value: Address) {
    this.setValueForInternal(() => {
      this._address = value;
    }, value, 'address');
  }

  // tslint:disable-next-line: variable-name
  private _companyInformation: CompanyInformation;

  @Expose()
  @Type(() => CompanyInformation)
  public get companyInformation(): CompanyInformation {
    return this.getValueForInternal<CompanyInformation>(
      (value) => {
        this._companyInformation = value;
      },
      this._companyInformation,
      'companyInformation',
      CompanyInformation
    );
  }

  public set companyInformation(value: CompanyInformation) {
    this.setValueForInternal(() => {
      this._companyInformation = value;
    }, value, 'companyInformation');
  }


  // tslint:disable-next-line: variable-name
  private _synchronizationData: SynchronizationData;
  @Expose()
  @Type(() => SynchronizationData)
  public get synchronizationData(): SynchronizationData {
    return this.getValueForInternal<SynchronizationData>(
      (value) => {
        this._synchronizationData = value;
      },
      this._synchronizationData,
      'synchronizationData',
      SynchronizationData
    );
  }

  public set synchronizationData(value: SynchronizationData) {
    this.setValueForInternal(() => {
      this._synchronizationData = value;
    }, value, 'synchronizationData');
  }
}

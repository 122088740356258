import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BaseRoutes, EmptyContainerComponent } from '@nts/std';
import { environment } from 'src/environments/environment';
import { TENANTS_ACTIVATION_FULL_PATH } from './tenants-activation/tenants-activation.module';
import { LICENSE_REPORT_FULL_PATH } from './license-report/license-report.module';

// Dichiarazione di tutti i full name utilizzati
export const EXTERNAL_APP_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.ExternalAppObjects.Models.ExternalApp';
export const EXTERNAL_APP_MODULES_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.ExternalAppModulesObjects.Models.ExternalAppModules';

export const MODULE_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.ModuleObjects.Models.Module';
export const MODULE_TENANTS_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantModuleObjects.Models.ModuleTenants';

export const TENANT_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantObjects.Models.Tenant';
export const TENANT_ROLES_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantRolesObjects.Models.TenantRoles';
export const TENANT_USERS_DATA_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantUsersDataObjects.Models.TenantUsersData';
export const TENANT_INVITED_USERS_DATA_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantInvitedUsersDataObjects.Models.TenantInvitedUsersData';
export const TENANT_MODULES_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantModuleObjects.Models.TenantModules';
export const PARTNER_MODULES_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.PartnerContractObjects.Models.PartnerContracts';
export const TENANT_INITIALIZATION_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantInitializationObjects.Models.TenantInitialization';
export const TENANT_ENTERPRISES_DOMAIN_MODEL_FULL_NAME = 'LicenseManagement.TenantEnterprisesObjects.Models.TenantEnterprises';
// Fine dichiarazione di tutti i full name utilizzati

const title = environment ? environment.appTitle : 'Application Title';
export const routes: Routes = [
  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantroles',
        () => import('./tenant-roles/tenant-roles.module').then(m => m.TenantRolesModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantroles',
        () => import('./tenant-roles/tenant-roles.module').then(m => m.TenantRolesModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantusersdata',
        () => import('./tenant-users-data/tenant-users-data.module').then(m => m.TenantUsersDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantinvitedusersdata',
        () => import('./tenant-invited-users-data/tenant-invited-users-data.module').then(m => m.TenantInvitedUsersDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenant',
        () => import('./tenant/tenant.module').then(m => m.TenantModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantinitialization',
        () => import('./tenant-initialization/tenant-initialization.module').then(m => m.TenantInitializationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantmodules',
        () => import('./tenant-modules/tenant-modules.module').then(m => m.TenantModulesModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'partnercontracts',
        () => import('./partner-contracts/partner-contracts.module').then(m => m.PartnerContractsModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'licenseconfiguration',
        () => import('./license-configuration/license-configuration.module').then(m => m.LicenseConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'softwareservice',
        () => import('./software-service/software-service.module').then(m => m.SoftwareServiceModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'module',
        () => import('./module/module.module').then(m => m.ModuleModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'moduletenants',
        () => import('./module-tenants/module-tenants.module').then(m => m.ModuleTenantsModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'objecttenantlevel',
        () => import('./object-tenant-level/object-tenant-level.module').then(m => m.ObjectTenantLevelModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'modulesofobject',
        () => import('./modules-of-object/modules-of-object.module').then(m => m.ModulesOfObjectModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'objectsofmodule',
        () => import('./objects-of-module/objects-of-module.module').then(m => m.ObjectsOfModuleModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'externalapp',
        () => import('./external-app/external-app.module').then(m => m.ExternalAppModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'externalappmodules',
        () => import('./external-app-modules/external-app-modules.module').then(m => m.ExternalAppModulesModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'userauthorizedappmanager',
        () => import('./user-authorized-app-manager/user-authorized-app-manager.module').then(m => m.UserAuthorizedAppManagerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'userkeyaccessappmanager',
        () => import('./user-key-access-app-manager/user-key-access-app-manager.module').then(m => m.UserKeyAccessAppManagerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tier',
        () => import('./tier/tier.module').then(m => m.TierModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'tenantenterprises',
        () => import('./tenant-enterprises/tenant-enterprises.module').then(m => m.TenantEnterprisesModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'subscriptionmanagerlongop',
        () => import('./subscription-manager-long-op/subscription-manager-long-op.module').then(m => m.SubscriptionManagerLongOpModule)
      ),
      {
        path: 'manage/userkeyaccessgenerator',
        component: EmptyContainerComponent,
        loadChildren: () => import('./user-key-access-generator/user-key-access-generator.module').then(m => m.UserKeyAccessGeneratorModule),
      },
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // #region rotte con autenticazione con container custom
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      {
        path: TENANTS_ACTIVATION_FULL_PATH,
        data: {
          title
        },
        loadChildren: () => import('./tenants-activation/tenants-activation.module').then(m => m.TenantsActivationModule)
      },
      {
        path: LICENSE_REPORT_FULL_PATH,
        data: {
          title
        },
        loadChildren: () => import('./license-report/license-report.module').then(m => m.LicenseReportModule)
      },
    ], () => import('@nts/std').then(mod => mod.EmptyContainerComponent),
  ),
  // #endregion rotte con autenticazione con container custom


  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

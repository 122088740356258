import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('ModuleIdentity')
export class ModuleIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _code: string;

    @Expose()
    public get code(): string {
        return this.getValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValue<string>(() => { this._code = value; }, value, 'code');
    }
}

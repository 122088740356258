<div class="main-container" *ngIf="rootViewModel">
    <nts-expandable [panelId]="'main'" [title]="'std_Expandable_Main_DisplayName' | NTSTranslate">
        <nts-labelled-text-box #expandableChild [propertyViewModel]="rootViewModel.code">
        </nts-labelled-text-box>

        <nts-labelled-text-box #expandableChild [propertyViewModel]="rootViewModel.title">
        </nts-labelled-text-box>

        <nts-labelled-text-box #expandableChild [propertyViewModel]="rootViewModel.isDisabled">
        </nts-labelled-text-box>

        <nts-labelled-text-box #expandableChild [propertyViewModel]="rootViewModel.sellMode">
        </nts-labelled-text-box>

        <nts-labelled-text-box #expandableChild [propertyViewModel]="rootViewModel.moduleType">
        </nts-labelled-text-box>

        <nts-labelled-text-box #expandableChild [propertyViewModel]="rootViewModel.defaultActivationMode">
        </nts-labelled-text-box>

        <nts-decode-text-box #expandableChild [externalPropertyViewModel]="rootViewModel.softwareServiceRef"
            [code]="rootViewModel.softwareServiceRef.code" [decodeDescription]="rootViewModel.softwareServiceRef.title">
        </nts-decode-text-box>
    </nts-expandable>

    <nts-expandable [panelId]="'multiLanguageDescriptions'" [disableAdditionalFields]="true"
        [title]="rootViewModel.multiLanguageDescriptions.metadataShortDescription">
        <nts-grid #expandableChild [dynamicHeight]="true" [fullColumn]="true"
            [collectionViewModel]="rootViewModel.multiLanguageDescriptions"
            [columns]="rootViewModel.multiLanguageDescriptionsColumns">
        </nts-grid>

        <nts-decode-text-box #expandableChild
            [externalPropertyViewModel]="rootViewModel.multiLanguageDescriptions.selectedItemOrMocked.languageRef"
            [code]="rootViewModel.multiLanguageDescriptions.selectedItemOrMocked.languageRef.languageCode"
            [decodeDescription]="rootViewModel.multiLanguageDescriptions.selectedItemOrMocked.languageRef.displayName">
        </nts-decode-text-box>

        <nts-labelled-text-box #expandableChild
            [propertyViewModel]="rootViewModel.multiLanguageDescriptions.selectedItemOrMocked.title">
        </nts-labelled-text-box>
    </nts-expandable>

</div>

import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('LanguageIdentity')
export class LanguageIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _languageCode: string;

    @Expose()
    public get languageCode(): string {
        return this.getValue<string>(() => this._languageCode, 'languageCode');
    }
    public set languageCode(value: string) {
        this.setValue<string>(() => { this._languageCode = value; }, value, 'languageCode');
    }
}


import { Component, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'nts-tenants-activation-container',
    templateUrl: './tenants-activation-container.component.html',
    styleUrls: ['tenants-activation-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterOutlet
    ]
})
export class TenantsActivationContainerComponent {


}

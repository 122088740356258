import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges, EventEmitter, Output, ElementRef, ViewChild, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { SafeResourceUrl, DomSanitizer, Meta } from '@angular/platform-browser';
import { MobileHelper } from '@nts/std/src/lib/utility';
import { RoutingService } from '../../routing/routing.service';
import { LoaderComponent } from '../shared/loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nts-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LoaderComponent,
        NgIf
    ]
})
export class IframeComponent implements OnChanges, AfterViewInit, OnDestroy {

    @Input() url: string;
    @Input() allow: boolean = false;
    @Input() sandbox: boolean = false;

    /**
     * In output c'è il native element dell'iframe
     */
    @Output()
    loading = new EventEmitter<any>();

    /**
     * Native element da l'accesso al native element dell'iframe
     */
    @ViewChild('iframe', { static: false })
    private _nativeElement: ElementRef;
    get nativeElement(): ElementRef {
        return this._nativeElement?.nativeElement;
    }

    iframeLoaded = false;
    urlSafe: SafeResourceUrl;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private routingService: RoutingService,
        private meta: Meta
    ) { }

    ngOnDestroy(): void {
        MobileHelper.zoomEnable(this.meta);
    }

    ngAfterViewInit(): void {
        MobileHelper.zoomDisable(this.meta);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['url'].currentValue !== changes['url'].previousValue) {
            this.iframeLoaded = false;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            this.iframeLoaded = true;
        }
    }

    @HostListener('window:blur', ['$event'])
    onWindowBlur(event: any): void {
        this.routingService.triggerIFrameFocusChanged(window);
    }
}

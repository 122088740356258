
import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, CoreModel } from '@nts/std';
import { TenantIdentity } from './tenant.identity';


@IdentityTypeDecorator(TenantIdentity)
@ModelTypeNameDecorator('ContactDetail')
export class ContactDetail extends CoreModel<TenantIdentity> {

  // tslint:disable-next-line: variable-name
  private _id: number;

  @Expose()
  public get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  public set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  // tslint:disable-next-line: variable-name
  private _phone: string;

  @Expose()
  public get phone(): string {
    return this.getValue<string>(() => this._phone, 'phone');
  }
  public set phone(value: string) {
    this.setValue<string>(() => { this._phone = value; }, value, 'phone');
  }

  // tslint:disable-next-line: variable-name
  private _eMail: string;

  @Expose()
  public get eMail(): string {
    return this.getValue<string>(() => this._eMail, 'eMail');
  }
  public set eMail(value: string) {
    this.setValue<string>(() => { this._eMail = value; }, value, 'eMail');
  }

  // tslint:disable-next-line: variable-name
  private _pec: string;

  @Expose()
  public get pec(): string {
    return this.getValue<string>(() => this._pec, 'pec');
  }
  public set pec(value: string) {
    this.setValue<string>(() => { this._pec = value; }, value, 'pec');
  }

  // tslint:disable-next-line: variable-name
  private _website: string;

  @Expose()
  public get website(): string {
    return this.getValue<string>(() => this._website, 'website');
  }
  public set website(value: string) {
    this.setValue<string>(() => { this._website = value; }, value, 'website');
  }
}

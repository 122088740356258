import { IdentityTypeNameDecorator, Expose, MultiLanguageIdentityInterface } from '@nts/std';
import { SoftwareServiceIdentity } from './software-service.identity';


@IdentityTypeNameDecorator('SoftwareServiceMLIdentity')
export class SoftwareServiceMLIdentity extends SoftwareServiceIdentity implements MultiLanguageIdentityInterface {

    // tslint:disable-next-line: variable-name
    private _languageCode: string;

    @Expose()
    public get languageCode(): string {
        return this.getValue<string>(() => this._languageCode, 'languageCode');
    }
    public set languageCode(value: string) {
        this.setValue<string>(() => { this._languageCode = value; }, value, 'languageCode');
    }
}



import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, CoreModel } from '@nts/std';
import { TenantIdentity } from './tenant.identity';


@IdentityTypeDecorator(TenantIdentity)
@ModelTypeNameDecorator('Address')
export class Address extends CoreModel<TenantIdentity> {

  // tslint:disable-next-line: variable-name
  private _id: number;

  @Expose()
  public get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  public set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  // tslint:disable-next-line: variable-name
  private _country: string;

  @Expose()
  public get country(): string {
    return this.getValue<string>(() => this._country, 'country');
  }
  public set country(value: string) {
    this.setValue<string>(() => { this._country = value; }, value, 'country');
  }

  // tslint:disable-next-line: variable-name
  private _province: string;

  @Expose()
  public get province(): string {
    return this.getValue<string>(() => this._province, 'province');
  }
  public set province(value: string) {
    this.setValue<string>(() => { this._province = value; }, value, 'province');
  }

  // tslint:disable-next-line: variable-name
  private _city: string;

  @Expose()
  public get city(): string {
    return this.getValue<string>(() => this._city, 'city');
  }
  public set city(value: string) {
    this.setValue<string>(() => { this._city = value; }, value, 'city');
  }

  // tslint:disable-next-line: variable-name
  private _zipCode: string;

  @Expose()
  public get zipCode(): string {
    return this.getValue<string>(() => this._zipCode, 'zipCode');
  }
  public set zipCode(value: string) {
    this.setValue<string>(() => { this._zipCode = value; }, value, 'zipCode');
  }

  // tslint:disable-next-line: variable-name
  private _street: string;

  @Expose()
  public get street(): string {
    return this.getValue<string>(() => this._street, 'street');
  }
  public set street(value: string) {
    this.setValue<string>(() => { this._street = value; }, value, 'street');
  }

  // tslint:disable-next-line: variable-name
  private _houseNumber: string;

  @Expose()
  public get houseNumber(): string {
    return this.getValue<string>(() => this._houseNumber, 'houseNumber');
  }
  public set houseNumber(value: string) {
    this.setValue<string>(() => { this._houseNumber = value; }, value, 'houseNumber');
  }
}

import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('TenantIdentity')
export class TenantIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    public get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    public set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}


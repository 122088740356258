import { Component } from "@angular/core";
import { OrchestratorViewModelInterface } from "../../../../view-models/orchestrator-view-model.interface";
import { CustomFieldMetaData } from "../../../../layout-meta-data/custom-field-meta-data";
import { BaseFieldComponent } from "../base-field/base-field.component";
import { CustomFieldComponentInterface } from "./custom-field-component.interface";

@Component({
    template: ''
})
export abstract class CustomFieldComponent extends BaseFieldComponent implements CustomFieldComponentInterface {

    abstract get uniqueId();
    abstract initComponentFromMetaData(metaData: CustomFieldMetaData, ovm: OrchestratorViewModelInterface);
}


import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, CoreModel } from '@nts/std';
import { TenantIdentity } from './tenant.identity';


@IdentityTypeDecorator(TenantIdentity)
@ModelTypeNameDecorator('SynchronizationData')
export class SynchronizationData extends CoreModel<TenantIdentity> {

  // tslint:disable-next-line: variable-name
  private _id: number;

  @Expose()
  public get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  public set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  // tslint:disable-next-line: variable-name
  private _isSynchronized: boolean;

  @Expose()
  public get isSynchronized(): boolean {
    return this.getValue<boolean>(() => this._isSynchronized, 'isSynchronized');
  }
  public set isSynchronized(value: boolean) {
    this.setValue<boolean>(() => { this._isSynchronized = value; }, value, 'isSynchronized');
  }


}

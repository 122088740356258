import { CoreModel } from './core-model';
import { BaseIdentity } from './base-identity';
import { NumberDecorator } from './decorators/number.decorator';
import { Expose } from '../serialization/class-transformer/decorators';

export abstract class BaseRowNumberModel<TIdentity extends BaseIdentity> extends CoreModel<TIdentity> {

    private internalRowNumber: number = 0;

    /**
     * Rappresenta il valore in base al quale la collection sarà ordinata
     */
    @NumberDecorator({ displayNameKey: 'BaseRowNumberModel_RowNumber' })
    @Expose()
    get rowNumber(): number {
        return this.getValue<number>(() => this.internalRowNumber, 'rowNumber');
    }
    set rowNumber(value: number) {
        this.setValue<number>(() => { this.internalRowNumber = value; }, value, 'rowNumber');
    }

}

import {
    StringPropertyViewModel, BoolPropertyViewModel, ExtColumnInfo,
    ColumnInfo, CollectionViewModelTypeDecorator, ExternalViewModelTypeDecorator, ColumnInfoCollection, CurrentMultiLanguageDescriptionPropertyViewModel, CustomPropertyViewModelDecorator, MetaDataUtils, StringMetaData, MasterDetailRootViewModel, EnumPropertyViewModel
} from '@nts/std';
import { Module } from '../domain-models/module';
import { ModuleIdentity } from '../domain-models/module.identity';
import { ModuleMLCollectionViewModel } from './module-ml.collection-view-model';
import { SoftwareServiceExtViewModel } from './software-service.ext-view-model';

export class ModuleViewModel extends MasterDetailRootViewModel<Module, ModuleIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: StringPropertyViewModel;

    public get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _title: CurrentMultiLanguageDescriptionPropertyViewModel;

    // Custom property view model
    @CustomPropertyViewModelDecorator()
    public get title(): CurrentMultiLanguageDescriptionPropertyViewModel {
        if (this._title == null) {
            const propertyName = 'title';
            const metaData = this.domainModelMetaData.strings.find(item => MetaDataUtils.toCamelCase(item.name) === propertyName);
            const init = this.createPVMInitializationInfo<StringMetaData>(propertyName, metaData, this);
            this._title = new CurrentMultiLanguageDescriptionPropertyViewModel(init, 'multiLanguageDescriptions', propertyName);

        }
        return this._title;
    }


    // tslint:disable-next-line: variable-name
    private _isDisabled: BoolPropertyViewModel;
    public get isDisabled(): BoolPropertyViewModel {
        return this.getBoolPropertyViewModel((value) => { this._isDisabled = value; }, this._isDisabled, 'isDisabled');
    }

    // tslint:disable-next-line: variable-name
    private _sellMode: EnumPropertyViewModel;
    public get sellMode(): EnumPropertyViewModel {
        return this.getEnumPropertyViewModel((value) => { this._sellMode = value; }, this._sellMode, 'sellMode');
    }

    // tslint:disable-next-line: variable-name
    private _moduleType: EnumPropertyViewModel;
    public get moduleType(): EnumPropertyViewModel {
        return this.getEnumPropertyViewModel((value) => { this._moduleType = value; }, this._moduleType, 'moduleType');
    }

    private _defaultActivationMode: EnumPropertyViewModel;
    public get defaultActivationMode(): EnumPropertyViewModel {
        return this.getEnumPropertyViewModel((value) => { this._defaultActivationMode = value; }, this._defaultActivationMode, 'defaultActivationMode');
    }

    @CollectionViewModelTypeDecorator(ModuleMLCollectionViewModel)
    public multiLanguageDescriptions: ModuleMLCollectionViewModel;

    // tslint:disable-next-line: member-ordering
    @ExternalViewModelTypeDecorator(SoftwareServiceExtViewModel)
    public softwareServiceRef: SoftwareServiceExtViewModel;

    // tslint:disable-next-line: variable-name
    private _multiLanguageDescriptionsColumns: ColumnInfoCollection;

    get multiLanguageDescriptionsColumns(): ColumnInfoCollection {
        if (!this._multiLanguageDescriptionsColumns) {
            this._multiLanguageDescriptionsColumns = this.getGridColumns(
                'multiLanguageDescriptions',
                'ModuleML',
                ['languageRef.languageCode', 'languageRef.displayName', 'title'],
                false
            );
        }
        return this._multiLanguageDescriptionsColumns;
    }


    public override async postInit(): Promise<void> {
        await super.postInit();
    }
}

import {
  IdentityTypeDecorator, ModelTypeNameDecorator, OCCModel, Expose, Type, MultiLanguageHostInterface
} from '@nts/std';
import { Tenant } from 'src/app/tenant/domain-models/tenant';
import { SoftwareServiceML } from './software-service-ml';
import { SoftwareServiceMLCollection } from './software-service-ml.collection';
import { SoftwareServiceMLIdentity } from './software-service-ml.identity';
import { SoftwareServiceIdentity } from './software-service.identity';

@IdentityTypeDecorator(SoftwareServiceIdentity)
@ModelTypeNameDecorator('SoftwareService')
export class SoftwareService extends OCCModel<SoftwareServiceIdentity> implements
  MultiLanguageHostInterface<SoftwareServiceML, SoftwareServiceMLIdentity, SoftwareServiceMLCollection> {

  // tslint:disable-next-line: variable-name
  private _code: string;

  @Expose()
  public get code(): string {
    return this.getValueForIdentityValue<string>(() => this._code, 'code');
  }
  public set code(value: string) {
    this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
  }

  // tslint:disable-next-line: variable-name
  private _title: string;

  @Expose()
  public get title(): string {
    return this.getValue<string>(() => this._title, 'title');
  }
  public set title(value: string) {
    this.setValue<string>(() => { this._title = value; }, value, 'title');
  }


  // tslint:disable-next-line: variable-name
  private _url: string;

  @Expose()
  public get url(): string {
    return this.getValue<string>(() => this._url, 'url');
  }
  public set url(value: string) {
    this.setValue<string>(() => { this._url = value; }, value, 'url');
  }


  // tslint:disable-next-line: variable-name
  private _hasLimitedAccess: boolean;
  @Expose()
  public get hasLimitedAccess(): boolean {
    return this.getValue<boolean>(() => this._hasLimitedAccess, 'hasLimitedAccess');
  }
  public set hasLimitedAccess(value: boolean) {
    this.setValue<boolean>(() => { this._hasLimitedAccess = value; }, value, 'hasLimitedAccess');
  }


  // tslint:disable-next-line: variable-name
  private _isDisabled: boolean;
  @Expose()
  public get isDisabled(): boolean {
    return this.getValue<boolean>(() => this._isDisabled, 'isDisabled');
  }
  public set isDisabled(value: boolean) {
    this.setValue<boolean>(() => { this._isDisabled = value; }, value, 'isDisabled');
  }

  // tslint:disable-next-line: variable-name
  private _publisherId: number;

  @Expose()
  public get publisherId(): number {
    return this.getValue<number>(() => this._publisherId, 'publisherId');
  }
  public set publisherId(value: number) {
    this.setValue<number>(() => { this._publisherId = value; }, value, 'publisherId');
  }


  // tslint:disable-next-line: variable-name
  private _publisherRef: Tenant;

  // tslint:disable-next-line: max-line-length
  @Expose()
  @Type(() => Tenant)
  public get publisherRef(): Tenant {
    return this.getValueForExternal<Tenant>(
      () => this._publisherRef,
      'publisherRef',
      Tenant
    );
  }
  public set publisherRef(value: Tenant) {
    this.setValueForExternal(() => {
      this._publisherRef = value;
    }, value, 'publisherRef');
  }


  // tslint:disable-next-line: variable-name
  private _multiLanguageDescriptions: SoftwareServiceMLCollection;

  @Expose()
  @Type(() => SoftwareServiceMLCollection)
  public get multiLanguageDescriptions(): SoftwareServiceMLCollection {
    return this.getValueForInternalCollection<SoftwareServiceMLCollection, SoftwareServiceML>(
      (value) => {
        this._multiLanguageDescriptions = value;
      },
      this._multiLanguageDescriptions,
      'multiLanguageDescriptions',
      SoftwareServiceMLCollection
    );
  }

  public set multiLanguageDescriptions(value: SoftwareServiceMLCollection) {
    this.setValueForInternalCollection<SoftwareServiceMLCollection, SoftwareServiceML>(() => {
      this._multiLanguageDescriptions = value;
    }, value, 'multiLanguageDescriptions');
  }


}

<div class="step-container">

    <div class="box-container">

        <div class="box-header">
            <div class="box-title">{{ 'TenantActivations_OperationComplete_Title' | NTSTranslate }}</div>
        </div>
        <div class="box-content">
            {{ message }}
        </div>
        <div class="box-actions">
            <nts-text-button [title]="'TenantActivations_OperationComplete_NewActivation' | NTSTranslate"
                [label]="'TenantActivations_OperationComplete_NewActivation' | NTSTranslate" (onClicked)="restart()">
            </nts-text-button>

            <nts-text-button [title]="'TenantActivations_OperationComplete_FollowOperation' | NTSTranslate"
                [label]="'TenantActivations_OperationComplete_FollowOperation' | NTSTranslate"
                (onClicked)="openOperation()">
            </nts-text-button>
        </div>
    </div>
</div>

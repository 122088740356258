import { Injectable } from '@angular/core';
import {
    ToastMessageService, RootViewModelTypeDecorator, IdentityTypeDecorator,
    ModalService, AuthService, MasterDetailOrchestratorViewModel, ToolBarViewModelInterface, OnlineService
} from '@nts/std';
import { ModuleViewModel } from './module.view-model';
import { ModuleIdentity } from '../domain-models/module.identity';
import { ModuleApiClient } from '../api-clients/module.api-client';
import { Module } from '../domain-models/module';
import { take } from 'rxjs/operators';
import { ModuleToolBarViewModel } from './module.tool-bar-view-model';
import { CreateResponse } from '@nts/std';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';

@Injectable()
@RootViewModelTypeDecorator(ModuleViewModel)
@IdentityTypeDecorator(ModuleIdentity)
export class ModuleOrchestratorViewModel extends
    MasterDetailOrchestratorViewModel<ModuleViewModel, ModuleApiClient, Module, ModuleIdentity> {

    constructor(
        apiClient: ModuleApiClient,
        modalService: ModalService,
        env: EnvironmentConfiguration,
        authService: AuthService,
        toastMessageService: ToastMessageService,
        onlineService: OnlineService
    ) {
        super(apiClient, modalService, env, authService, toastMessageService, onlineService);
    }

    public override getToolBarMenu(): ToolBarViewModelInterface {
        return new ModuleToolBarViewModel(this);
    }

    public override async initialize(): Promise<void> {
        await super.initialize();
        this.masterViewModel.setFilters([
            'softwareServiceRef'
        ]);
        this.masterViewModel.showExtendedFilter = true;
    }

    protected override async createWithClearAsync(clearPreviousMessages): Promise<CreateResponse<Module, ModuleIdentity>> {
        const createResponse = await this.createImplementationAsync(clearPreviousMessages);
        if (createResponse.operationSuccedeed && this.masterViewModel.extendedFilter?.softwareServiceRef?.code?.value?.length > 0) {
            this.rootViewModel.skipValidation = true;
            this.rootViewModel.softwareServiceRef.code.value = this.masterViewModel.extendedFilter?.softwareServiceRef?.code?.value;
            this.rootViewModel.softwareServiceRef.externalDomainModelChanged.pipe(take(1)).subscribe(() => {
                this.rootViewModel.skipValidation = false;
            });
        }
        return createResponse;
    }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'nts-license-report-container',
    templateUrl: './license-report-container.component.html',
    styleUrls: ['license-report-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterOutlet
    ]
})
export class LicenseReportContainerComponent {


}

import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DecodeTextBoxComponent, FilledButtonComponent, LabelledTextBoxComponent, NTSFilterPipe, NTSTranslatePipe, ToastMessageService } from '@nts/std';
import { Router } from '@angular/router';
import { LicenseReportApiClient } from '../../api-clients/license-report.api-client'
import { LicenseReportViewModel } from '../../view-models/license-report.view-model';
import { AsyncPipe } from '@angular/common';


@UntilDestroy()
@Component({
    selector: 'nts-license-report',
    templateUrl: './license-report.component.html',
    styleUrls: ['./license-report.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
    standalone: true,
    imports: [
        NTSTranslatePipe,
        FilledButtonComponent,
        AsyncPipe,
        LabelledTextBoxComponent
    ]
})
export class LicenseReportComponent implements OnInit {

    viewModel: LicenseReportViewModel = null;

    constructor(
        private apiClient: LicenseReportApiClient,
        private toastMessageService: ToastMessageService,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {

    }

    async ngOnInit() {
        this.viewModel = new LicenseReportViewModel(
            this.apiClient,
            this.toastMessageService
        );
    }
}

<div class="main-container">

    <div class="license-report-container">

        <div class="license-report-header">
            {{ "LicenseReport" | NTSTranslate }}
        </div>

        <div class="license-report-content">

            <ul>
                <li>
                    <nts-labelled-text-box [propertyViewModel]="viewModel.fromDate"
                        [fullColumn]="true"></nts-labelled-text-box>
                </li>

                <li>
                    <nts-labelled-text-box [propertyViewModel]="viewModel.toDate"
                        [fullColumn]="true"></nts-labelled-text-box>
                </li>

            </ul>
        </div>

        <div class="license-report-footer">
            <nts-filled-button [type]="3" [title]="'CMD_LicenseReport_DownloadCSV_DisplayName' | NTSTranslate"
                [label]="'CMD_LicenseReport_DownloadCSV_DisplayName' | NTSTranslate"
                [icon]="(viewModel.downloading | async) ? 'refresh-animated': 'download'"
                [isLoading]="viewModel.downloading | async"
                [isDisabled]="viewModel.fromDate.value == null || viewModel.toDate.value == null || (viewModel.downloading | async)"
                (onClicked)="viewModel.downloadCsv()">
            </nts-filled-button>

        </div>

    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageResourceManager, NTSTranslatePipe, RouteChangeParams, RoutingService, TextButtonComponent } from '@nts/std';
import { OperationIdentity } from 'src/app/external-remote/spooler/operation.identity';
import { UploadResponseDto } from '../../dto/upload-response.dto';
import { FormDataService, STEPS } from '../../services/form-data.service';
import { TENANTS_ACTIVATION_FULL_PATH } from '../../tenants-activation.module';

@Component({
    selector: 'nts-operation-complete',
    templateUrl: './operation-complete.component.html',
    styleUrls: ['./operation-complete.component.scss'],
    standalone: true,
    imports: [
        NTSTranslatePipe,
        TextButtonComponent
    ]
})
export class OperationCompleteComponent implements OnInit {

    // public currentData: OperationCompleteDataInterface;
    public message: string;
    public operationIdentity: OperationIdentity;
    public rootModelFullName: string;
    public currentData: UploadResponseDto;

    constructor(
        private routingService: RoutingService,
        private formDataService: FormDataService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        // Recupero i dati aggiunti in questo step
        this.currentData = this.formDataService.getData<UploadResponseDto>(STEPS.selectModule);
        this.message = MessageResourceManager.Current.getMessage('TenantActivations_OperationComplete_InfoMessage');
        this.operationIdentity = this.currentData.operationIdentity;
        this.rootModelFullName = this.currentData.rootModelFullName;
    }

    public async openOperation() {
        const routeChangeParams = new RouteChangeParams();
        routeChangeParams.rootModelFullName = this.rootModelFullName;
        routeChangeParams.routeParam = RoutingService.encodeObject(this.operationIdentity);
        this.routingService.routeChangeRequested.next(routeChangeParams)
    }

    public restart() {
        const baseUrl = `${TENANTS_ACTIVATION_FULL_PATH}/${STEPS.selectModule}`;
        this.router.navigate([baseUrl]);
    }
}

import {
    IdentityTypeDecorator, ModelTypeNameDecorator, Expose, StringDecorator,
    Type, BaseMultiLanguageModel, NumberDecorator
} from '@nts/std';
import { SoftwareServiceMLIdentity } from './software-service-ml.identity';
import { Language } from 'src/app/external-remote/language/language';


@IdentityTypeDecorator(SoftwareServiceMLIdentity)
@ModelTypeNameDecorator('SoftwareServiceML')
export class SoftwareServiceML extends BaseMultiLanguageModel<SoftwareServiceMLIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: string;
    public get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _title: string;

    @Expose()
    public get title(): string {
        return this.getValue<string>(() => this._title, 'title');
    }
    public set title(value: string) {
        this.setValue<string>(() => { this._title = value; }, value, 'title');
    }

    // tslint:disable-next-line: variable-name
    private _subtitle: string;

    @Expose()
    public get subtitle(): string {
        return this.getValue<string>(() => this._subtitle, 'subtitle');
    }
    public set subtitle(value: string) {
        this.setValue<string>(() => { this._subtitle = value; }, value, 'subtitle');
    }

    // tslint:disable-next-line: variable-name
    private _languageRef: Language;

    @Expose()
    @Type(() => Language)
    public get languageRef(): Language {
        return this.getValueForExternal<Language>(
            () => this._languageRef,
            'languageRef',
            Language
        );
    }
    public set languageRef(value: Language) {
        this.setValueForExternal(() => {
            this._languageRef = value;
        }, value, 'languageRef');
    }
}
